import { Box, Paper } from '@mui/material'
import { ResponsiveFunnel } from '@nivo/funnel'
import Title from './Title'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { TaskType } from './Interfaces'
import React from 'react'



  
export default function ProgressChart({ProjectId}:{ProjectId:string}) {

    const [Tasks, setTasks] = React.useState<TaskType[]>();

    const [CreatedCount, setCreatedCount] = React.useState<number>(0);
    const [AssignedCount, setAssignedCount] = React.useState<number>(0);
    const [InProgressCount, setInProgressCount] = React.useState<number>(0);
    const [InReviewCount, setInReviewCount] = React.useState<number>(0);
    const [DoneCount, setDoneCount] = React.useState<number>(0);

    const [data, setdata] = React.useState(
        [
            {
              "id": "step_Created",
              "value": 0,
              "label": "Created"
            },
            {
              "id": "step_Assigned",
              "value": 0,
              "label": "Assigned"
            },
            {
              "id": "step_progress",
              "value": 0,
              "label": "In progress"
            },
            {
              "id": "step_Review",
              "value": 0,
              "label": "In Review"
            },
            {
              "id": "step_Done",
              "value": 0,
              "label": "Done"
            }
          ]
    );



    function loadDB()
    {
  
    const fetchTasks = async () => {
        console.log(ProjectId);
        const q = query(collection(db, "Tasks"), where("project.projectID", "==", ProjectId));
        const querySnapshot = await getDocs(q);
    
        let Tasks:TaskType[]=[];
        querySnapshot.docs.forEach(doc=>{
              let Task:TaskType;
              Task=doc.data();
              Task.id = doc.id;
              Tasks.push(Task);
            })
            setTasks(Tasks);
          }
          fetchTasks().catch(console.error);   
    }

          
    React.useEffect(loadDB,
        []
        );


    React.useEffect(
        ()=>{
            console.log(Tasks)
            if(Tasks!==undefined)
            {
                setCreatedCount(Tasks?.length);
                setAssignedCount(Tasks?.filter(task=>task.status!=='Unassigned').length);
                setInProgressCount(Tasks?.filter(task=>task.status=='Active').length);
                setInReviewCount(Tasks?.filter(task=>task.status=="InReview").length);
                setDoneCount(Tasks?.filter(task=>task.status=="Done").length);    
            }
        }
        ,
        [Tasks]
        );

    React.useEffect(
        ()=>{
            setdata(
                [
                    {
                      "id": "step_Created",
                      "value": CreatedCount,
                      "label": "Created"
                    },
                    {
                      "id": "step_Assigned",
                      "value": AssignedCount,
                      "label": "Assigned"
                    },
                    {
                      "id": "step_progress",
                      "value": InProgressCount,
                      "label": "In progress"
                    },
                    {
                      "id": "step_Review",
                      "value": InReviewCount,
                      "label": "In Review"
                    },
                    {
                      "id": "step_Done",
                      "value": DoneCount,
                      "label": "Done"
                    }
                  ]
            );
        }
        ,
        [CreatedCount, AssignedCount, InProgressCount, InReviewCount, DoneCount]
        );
    
    
      

    return (

<Paper elevation={0} 
sx={{
    py: 2,
    my: 2,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
}}>
    <Title >Tasks Progress</Title>


    <Box sx={{height:250}}>
    <ResponsiveFunnel
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        valueFormat=">-.1s"
        colors={{ scheme: 'spectral' }}
        borderWidth={20}
        labelColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    3
                ]
            ]
        }}
        beforeSeparatorLength={100}
        beforeSeparatorOffset={20}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={10}
        currentBorderWidth={40}
        motionConfig="wobbly"
    />
    </Box>
</Paper>
)
}
