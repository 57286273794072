import { Container, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CashDisplay from "./CashDisplay";
import { auth, db } from "../firebase";
//import EquityPieChart from "./EquityPieChart";
import { UserType } from "./Interfaces";
import { doc, getDoc } from "firebase/firestore";
import { useGlobalState as useFirebaseGlobalState } from "./Settings" ;
import { useHistory } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import AdminView from "./AdminView";

export default function Dashboard()
{

    const [user, loading, error] = useAuthState(auth);

    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";
    useEffect(() =>{
        currentUserId = user?.uid;
        currentUserName = user?.displayName??"";
    },
    [user]
    );

    const [User, setUser] = useState<UserType>({});
    const [useFirebase] = useFirebaseGlobalState('useFirebase');
       
    useEffect(() =>{
        const fetchData = async () => {
        const docRef = doc(db, "Users", currentUserId??"");
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            let User:UserType;
            User=docSnap.data();
            setUser(User);
        } else {
            // doc.data() will be undefined in this case
        }
        }
        fetchData().catch(console.error);  
    },
    []
    );


    return(

    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <CashDisplay text={'Account Cash Balance'} CashValue={User.cash??0}/>
        <AdminView/>
    </Container>

    );
}
