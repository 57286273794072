import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { Paper } from '@mui/material';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function CashDisplay({text, CashValue}:{text:String, CashValue:Number}) {
  return (
      <Paper elevation={0}
      sx={{
        py: 2,
        my: 2,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        ":hover": {boxShadow: 15},
      }}>
        <Title >{text}</Title>
        <Typography component="p" sx={{fontSize:15}}>
          {CashValue}
        </Typography>
      </Paper>
  );
}
