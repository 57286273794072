import { Avatar, Box, Paper, TextField, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import Title from "./Title";
import { blue, deepOrange } from "@mui/material/colors";
import { CommentType, ErrorLogType } from "./Interfaces";
import useState from 'react-usestateref';
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { useEffect } from "react";
import { addDoc, collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import UserAvatar from "./UserAvatar";


export default function CommentSection({itemID, setDatabaseStatusRequest, setDatabaseErrorDesc}
    :{itemID:string, setDatabaseStatusRequest:Function, setDatabaseErrorDesc:Function}) {

    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";

    const [Comments, setComments, CommentsRef] = useState<CommentType[]>([]);
    const [Newcomment, setNewcomment, NewcommentRef] = useState<CommentType>(
        {UserId: currentUserId??"", 
        UserName: currentUserName, 
        CommentText: "", 
        ItemId: itemID, 
        date: new Date().toUTCString(),
        isDirty:false});

    var getInitials = function (string:string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(
        ()=>{
            setNewcomment({...Newcomment, ItemId:itemID});
            if(itemID!==undefined)
            {
                loadDB();
            }
        }
        ,[itemID]);


    function keyPressHandler(e:any) {
        if(e.key == 'Enter') {
            {
                setComments([...Comments, Newcomment]); 
                setNewcomment({...Newcomment, CommentText: ""})
            }
        }
    }

    function loadDB(){

        console.log("CommentSection: loadDB: itemID: "+itemID);
        const fetchComments = async () => {
            const q = query(collection(db, "Comments"), where("ItemId", "==", itemID));
            const querySnapshot = await getDocs(q);
              let Comments:CommentType[]=[];
              querySnapshot.docs.forEach(doc=>{
                let Comment:CommentType;
                Comment=doc.data() as CommentType;
                Comment.id = doc.id;
                Comment.isDirty = false;
                Comments.push(Comment);
              })
              Comments.sort((a,b)=>{return (Date.parse(a.date)-Date.parse(b.date))});
              setComments(Comments);
            }
            fetchComments().catch((e)=>{ErrorHandler(e, "Fetch Comments")}).then(()=>{setDatabaseStatusRequest("OK");});  

    }
    
    async function saveDBEdit(){
        let activeCycle=false;

        CommentsRef.current.forEach(async (comment,i)=>{
            if(comment.isDirty==true)
            {   
                activeCycle=true;
                if(comment.id==undefined)
                {
                    await addDoc(collection(db, "Comments"), comment ).then((docRef)=>{
                        setComments(CommentsRef.current?.map((comment,j)=>{if(i==j){comment.isDirty=false; comment.id=docRef.id}return comment;})
                    )}).catch((e)=>{ErrorHandler(e, "Add Comment");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
                else
                {
                    await setDoc(doc(db, "Comments", comment.id??""), comment).then(()=>{
                        setComments(CommentsRef.current?.map((comment,j)=>{if(i==j){comment.isDirty=false; }return comment;})
                    )}).catch((e)=>{ErrorHandler(e, "Edit Comment");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
            }
        })

        if(activeCycle==false)
        {
            if(CommentsRef?.current.some(request=>request.isDirty==true))
            {
                setDatabaseStatusRequest("DatabaseError");
            }
        }
        
    }

    function updateState(){
        setNewcomment({...NewcommentRef.current, date: new Date().toUTCString()});
    }


    async function ErrorHandler(error:Error, SourceFunction:string)
    {
      let errorLog:ErrorLogType = {file: "CommentSection", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }    
      setDatabaseStatusRequest("DatabaseError");
      setDatabaseErrorDesc(errorLog);
    }


    useEffect(
        ()=>{
            //loadDB();
            const interval = setInterval(() => {
                updateState();
                saveDBEdit();
            },2*1000);
            return () => clearInterval(interval);
        },[]);


        useEffect(
            ()=>{
                console.log(CommentsRef.current)
            },[CommentsRef.current]);

    
    return (
        <Paper elevation={0} 
        sx={{
            p: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            alignItems: 'canter',
            //":hover": {boxShadow: 15},
        }}>
            <Title>Comments</Title>

            {CommentsRef.current?.map((comment, index) => (
            <Paper elevation={3} 
            sx={{
                p: 2,
                m: 2,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 7,
                alignItems: 'canter',
                backgroundColor: '#DCDCDC',
                ":hover": {boxShadow: 15},
            }}>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignContent:'center'}}>   
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <Link  style={{'textDecoration':"none"}} to={"/Home/User/"+comment.UserId}  >
                <Tooltip sx={{alignSelf:'center'}} title={comment.UserName} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <div>
                <UserAvatar userId={comment.UserId} userName={comment.UserName} callContext="Messages"/>   
                </div>
                </Tooltip>
                </Link>
                </Box>

                <Typography  sx={{px:2, alignSelf:'center', textAlign:'start', fontSize:'min(3.5vw,13px)', width:'100%'}}>{comment.CommentText}</Typography>
                </Box>
                <Typography sx={{alignSelf:'end', fontSize:'min(2.5vw,10px)'}}>{comment.date}</Typography> 

            </Paper>
            ))}

            <Paper elevation={3} 
            sx={{
                p: 2,
                m: 2,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 7,
                alignItems: 'canter',
                backgroundColor: '#DCDCDC',
                ":hover": {boxShadow: 15},
            }}>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignContent:'center'}}>   
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <Link style={{'textDecoration':"none"}} to={"/Home/User/"+currentUserId} >
                <Tooltip sx={{alignSelf:'center'}} title={currentUserName} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <div>
                <UserAvatar userId={currentUserId!} userName={currentUserName} callContext="Messages"/>   
                </div>

                </Tooltip>
                </Link>
                </Box>

                <TextField
                id="outlined-multiline-flexible"
                label="Add a comment.."
                multiline
                fullWidth
                variant="standard"
                value={Newcomment?.CommentText||''}
                onChange={(e) => 
                    {
                        console.log(Newcomment);
                        setNewcomment({...Newcomment, CommentText: (e.target.value), isDirty:true})
                    }}
                onKeyPress={keyPressHandler}
                sx={{mx:2, pb:1}}
                />

                </Box>
            </Paper>

            
            {/* <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Add a comment.."
                        //value={task?.description||''}
                        style={{ width: '100%', margin:"normal"}}
                        onChange={(e) => {
                        }}
                        //readOnly= {!(IsCreateNotEdit)}
                    /> */}

        </Paper>
    );
}