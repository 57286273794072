import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';
import { ProjectType } from './Interfaces'
import { Box, CardActions, CardMedia, Divider } from '@mui/material'
import StreamChart from './StreamChart'
import { CardOverflow } from '@mui/joy'
import { useState } from 'react'
import React from 'react'

export default function ProjectCard({project}:{project:ProjectType}) {


  const currentDate = new Date().toUTCString();
  const [DatePhrase, setDatePhrase] = useState<string>();
  
  React.useEffect(
    ()=>{
      const Minutes = parseInt((((Date.parse(currentDate) - Date.parse(project.created!))/1000)/60).toFixed(0));
      const Hours = Math.floor(Minutes/60);
      const Days = Math.floor(Hours/24);
      const Months = Math.floor(Days/30);

      if(Minutes < 60)
      {
        setDatePhrase("Created "+Minutes+" minutes ago.")
      }
      else if(Hours < 24)
      {
        setDatePhrase("Created "+Hours+" hours ago.")
      }
      else if(Days < 30)
      {
        setDatePhrase("Created "+Days+" days ago.")
      }
      else if(Days < 30)
      {
        setDatePhrase("Created "+Months+" months ago.")
      }
  
  },[]);

  return (
      <Card 
        elevation={3} 
        sx={{backgroundColor:'#F0F0F0',
          borderRadius: 5,
          height:400,
          ':hover': {
            boxShadow: 15,
            backgroundColor:'white'
          },}}
      >
        <CardActionArea component={(props:any) => <Link {...props} to={"/Home/Project/"+project.id} />} >

        <Box sx={{height:400, width:'100%', position:"absolute"}}>
          <StreamChart/>
        </Box>

          <CardHeader 
            title={project.projectTitle}
            subheader= {project.category}
          />

          <CardContent sx={{height:300, display:'flex', flexDirection:'column', justifyContent:'space-between', alignContent:'space-between'}}>


          <Typography variant="body2" color="textSecondary">
            { project.description }
          </Typography>  

          <CardActions>
          <Typography variant="body2" color="textSecondary" 
            >{DatePhrase}
          </Typography>
          </CardActions>

        </CardContent>
    </CardActionArea>
  </Card>
  )
}
