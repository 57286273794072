import { Autocomplete, Avatar, Badge, Box, Button, Container, createFilterOptions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Paper, Rating, Slide, styled, TextareaAutosize, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useCallback, useEffect/*, useState*/ } from "react";
import useState from 'react-usestateref';
import { Link, useHistory, useParams } from "react-router-dom";
import { auth, db } from "../firebase";
import { CategoryType, ErrorLogType, PaymentMethodType, projectMembershipType, ProjectType, RatingItem, ReviewType, UserType } from "./Interfaces";
import Title from "./Title";
import { useGlobalState as useFirebaseGlobalState } from "./Settings" ;
import { useAuthState } from "react-firebase-hooks/auth";
import { blue } from "@mui/material/colors";
import EditIcon from '@mui/icons-material/Edit';
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import FileDropZone from "./FileDropZone";
import UserAvatar from "./UserAvatar";
import useWindowDimensions from "./useWindowDimensions";
import ReviewPanel from "./ReviewPanel";
import { set } from "date-fns";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function User({setDatabaseStatusRequest, setDatabaseErrorDesc}:
    {setDatabaseStatusRequest:Function, setDatabaseErrorDesc:Function}
)
{
    let { id } = useParams<{id: string}>();

    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";

    useEffect(() =>{
        currentUserId = user?.uid;
        currentUserName = user?.displayName??"";
    },
    [user]
    );
    
    const [User, setUser, UserRef] = useState<UserType>({});
    const [Users, setUsers] = useState<UserType[]>([]);
    const [ProjectsMemberships, setProjectsMemberships] = useState<projectMembershipType[]>([]);
    const [userRatings, setUserRatings, userRatingsRef] = useState<ReviewType[]>([]);
    const [useFirebase] = useFirebaseGlobalState('useFirebase');
  
    const [openAvatarPanel, setopenAvatarPanel] = React.useState(false);
    const [NavigationValues, setNavigationValues] = React.useState<string[]>(() => ["Details"]);

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodType[]>([]);
    const filter = createFilterOptions();


    const handleClickOpenAvatarPanel = () => {
        setopenAvatarPanel(true);
    };
  
    const handleCloseAvatarPanel = () => {
        setopenAvatarPanel(false);
        window.location.reload();
    };  

    let IsSelf = (id  == currentUserId) ? true:false;

    let IsColleague = false;
    let ProjectSet1 = new Set();
    let ProjectSet2 = new Set();
    ProjectsMemberships?.forEach(membership=>{   
        if(membership.userId==currentUserId && membership.status=="active")
        {
            ProjectSet1.add(membership.projectId);
        }
        if(membership.userId==id && membership.status=="active")
        {
            ProjectSet2.add(membership.projectId);
        }
    })
    ProjectSet1.forEach(Set1Element=>{
        if(ProjectSet2.has(Set1Element))
        {
            IsColleague = true;
        }
    })

    let helpMessage = "";
    let helpDescription = "";
    if(IsSelf)
    {
        helpMessage = "Your Account";
        helpDescription = "You may update your account details.";
    }
    else if(IsColleague)
    {
        helpMessage = "A Colleague";
        helpDescription = "This account belongs to a colleague in one of your projects, you may rate his performance.";
    }
    else
    {
        helpMessage = "Not A Colleague";
        helpDescription = "This account does not belong to a colleague in any project.";
    }

    const history = useHistory();

    const HandleUserSearch = (e:any) => {
        let targetUser:UserType = Users?.find(userItem => (userItem.name==e.target.textContent)) ?? {} as UserType;
        history.push('/Home/User/'+targetUser?.uid); 
    };


    var getInitials = function (string:string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };


    function calcAvgUserRating()
    {
        let avgRating = 0.0;
        let sum = 0;
        let count = 0;
        userRatings.filter(ratingItem=>ratingItem.targetId==id).forEach(ratingItem=>{
            sum += ratingItem?.Rating??0;
            count++;
        })
        avgRating = sum/(count);
        return avgRating;
    }

    const handleRatingChange = (e:any) => {

        //Create new rating
        if(!userRatings.find(rating=>( (rating.authorId==currentUserId) && (rating.targetId==id) )))
        {
            let ratingItem:ReviewType={authorId:currentUserId, targetId:id, Rating:parseInt(e.target.value), isDirty:true}
            setUserRatings([...userRatings, ratingItem]);
            setDatabaseStatusRequest("Sync");
        }
        //Modify old rating
        else
        {
            setUserRatings(
                userRatings?.map(ratingItem=>
                    {
                        if(ratingItem.authorId==currentUserId && ratingItem.targetId==id)
                        {
                            ratingItem.Rating = parseInt(e.target.value);
                            ratingItem.isDirty = true;
                            setDatabaseStatusRequest("Sync");
                        }
                        return ratingItem;
                    }));
        }
    };

    function HandleReset(e:any)
    {
        loadDB();
    }

    async function saveDBEdit()
    {
        let activeCycle=false;
        if(UserRef.current.isDirty)
        {
            activeCycle=true;
            await setDoc(doc(db, "Users", id), UserRef.current).then(()=>{setUser({...UserRef.current, isDirty:false});})
            .catch((e)=>{ErrorHandler(e, "Update User");}).then(()=>{setDatabaseStatusRequest("OK");});
        }

        userRatingsRef?.current.forEach(async (rating, i)=>{
            if(rating.isDirty==true)
            {
                activeCycle=true;
                if(rating.id)
                {
                    await setDoc(doc(db, "UserRatings", rating.id??""), rating).then(()=>{
                        setUserRatings(userRatingsRef?.current.map((rating, j)=> {if(i==j){rating.isDirty=false;} return rating;}))
                }).catch((e)=>{ErrorHandler(e, "Update User rating");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
                else
                {
                    await addDoc(collection(db, "UserRatings"), rating ).then(()=>{ 
                        setUserRatings(userRatingsRef?.current.map((rating, j)=> {if(i==j){rating.isDirty=false;} return rating;}))
                    }).catch((e)=>{ErrorHandler(e, "Update User rating");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
            }
        })

        if(activeCycle==false)
        {
            let status = "";
            if(UserRef.current.isDirty==true)
            {
                setDatabaseStatusRequest("DatabaseError");
            }
            else if(userRatingsRef?.current.some(rating=>rating.isDirty==true))
            {
                setDatabaseStatusRequest("DatabaseError");
            }
        }
    }


    function loadDB()
    {

        const fetchUser = async () => {
        const docRef = doc(db, "Users", id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            let User:UserType;
            User=docSnap.data();
            User.isDirty = false;
            setUser(User);
        } else {
        }
        }
        fetchUser().catch((e)=>{ErrorHandler(e, "Fetch user");}).then(()=>{setDatabaseStatusRequest("OK");});


        
        const fetchUsers = async () => {
        const querySnapshot = await getDocs(collection(db, "Users"));

        let Users:UserType[]=[];
        querySnapshot.docs.forEach(doc=>{
            let User:UserType;
            User=doc.data();
            Users.push(User);
        })
        setUsers(Users)
        }

        fetchUsers().catch((e)=>{ErrorHandler(e, "Fetch users");}).then(()=>{setDatabaseStatusRequest("OK");});   

        
        const fetchProjectsMemberships = async () => {
            const q = query(collection(db, "ProjectsMemberships"));
            const querySnapshot = await getDocs(q);
              let projectsMemberships:projectMembershipType[]=[];
              querySnapshot.docs.forEach(doc=>{
                let projectMembership:projectMembershipType;
                projectMembership=doc.data()as projectMembershipType;
                projectMembership.id = doc.id;
                projectsMemberships.push(projectMembership);
              })
              setProjectsMemberships(projectsMemberships)
            }
          fetchProjectsMemberships().catch((e)=>{ErrorHandler(e, "Fetch users");}).then(()=>{setDatabaseStatusRequest("OK");});  

        const fetchUserRatings = async () => {
        const querySnapshot = await getDocs(collection(db, "UserRatings"));

        let UserRatings:ReviewType[]=[];
        querySnapshot.docs.forEach(doc=>{
            let Rating:ReviewType;
            Rating=doc.data();
            Rating.id = doc.id;
            Rating.isDirty = false;
            UserRatings.push(Rating);
        })
        setUserRatings(UserRatings);
        }
        fetchUserRatings().catch((e)=>{ErrorHandler(e, "Fetch user rating");}).then(()=>{setDatabaseStatusRequest("OK");});   


        const fetchPaymentMethods = async () => {
            const querySnapshot = await getDocs(collection(db, "PaymentMethods"));
            let paymentMethods:PaymentMethodType[]=[];
            querySnapshot.docs.forEach(doc=>{
                let paymentMethod:PaymentMethodType;
                paymentMethod=doc.data();
                paymentMethod.id = doc.id;
                paymentMethods.push(paymentMethod);
            })
            paymentMethods.sort((a:PaymentMethodType, b:PaymentMethodType) => a.title?.localeCompare(b.title??"")??0);
            setPaymentMethods(paymentMethods);
            }
            fetchPaymentMethods().catch((e)=>{ErrorHandler(e, "Fetch payment methods");}).then(()=>{setDatabaseStatusRequest("OK");});  
        }

    useEffect(()=>{
        loadDB();

        const interval = setInterval(() => {
            saveDBEdit();
        },2*1000);
        return () => clearInterval(interval);
    },
    []
    );

    useEffect(() =>{
        loadDB();
    },
    [id]
    );

    function capitalizeFirstLetter(string:string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
      }));

    async function ErrorHandler(error:Error, SourceFunction:string)
    {    
    let errorLog:ErrorLogType = {file: "User", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }    
    setDatabaseStatusRequest("DatabaseError");
    setDatabaseErrorDesc(errorLog);
    }
  
      
    return(
    <Container>

    <Paper elevation={0} 
        sx={{
            p: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5
        }}>
        
       <Autocomplete
        disablePortal
        sx={{width:'75%', m:1, alignSelf:'center'}}
        id="User-search"
        options={Users}
        getOptionLabel={(User) => User.name??""}
        renderOption={(props, User) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <UserAvatar userId={User.uid!} userName={User.name!} callContext="Messages" />
                <Typography sx={{ m:1 }} >{User.name}</Typography>
            </Box>
          )}

        onChange={HandleUserSearch}
        renderInput={(params) => (
        <TextField {...params} label="Find users .." />
        )}
        />
        
    </Paper>

    <Tooltip title={helpDescription} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
    <Paper elevation={0} 
        sx={{
            py: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5
        }}>
        <Typography><strong>{helpMessage}</strong></Typography>
    </Paper>
    </Tooltip>

    <Paper elevation={0} 
    sx={{
        py: 2,
        my: 2,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        alignItems: 'center',
    }}>

        <ToggleButtonGroup
        value={NavigationValues}
        exclusive
        onChange={(event, values)=>{setNavigationValues(values);}}
        aria-label="text formatting"
        orientation= {(useWindowDimensions().width<800)? "vertical":"horizontal"}
        >
            <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Details">Details</ToggleButton>
            <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Reviews" >Reviews</ToggleButton>
            <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Payment" >Payment</ToggleButton>
        </ToggleButtonGroup>
    </Paper>

    {NavigationValues.includes("Details") && 
    <Paper elevation={0} 
    sx={{
        p: 2,
        my: 2,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5
    }}>

        <Box sx={{alignSelf:'center'}}>
        <Tooltip 
        onDoubleClick={()=>{if(IsSelf) {handleClickOpenAvatarPanel();}}} 
        title={IsSelf ? "Double click to change Avatar":""} 
        enterTouchDelay={0} leaveTouchDelay={5000} arrow>
            <div >
            <UserAvatar userId={User.uid!} userName={User.name!} callContext="UserAccount" />
            </div>
        </Tooltip>
        </Box>
        <Dialog
            maxWidth="lg"
            open={openAvatarPanel}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{setopenAvatarPanel(false);}}
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogContent>
                <FileDropZone closeDialog={handleCloseAvatarPanel} />
        </DialogContent>

      </Dialog>



        <Box sx={{my:1}}>
        <TextField
            id="outlined-helperText"
            label="User Name"
            value={User?.name||''}
            onChange={(e) => {
                setUser({...User, name:e.target.value, isDirty: true}); 
                setDatabaseStatusRequest("Sync");
            }}
            helperText=""
            margin="none"
            fullWidth
            InputProps={{
                readOnly: (!IsSelf)
            }}
        />
        </Box>

        <Box sx={{my:1}}>
        <TextField
            id="outlined-helperText"
            label="User ID"
            value={User?.uid||''}
            helperText=""
            margin="none"
            fullWidth
            InputProps={{
                readOnly: (true)
            }}
        />
        </Box>

        <Box sx={{mt:1}}>
        <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Biography"
            value={User?.bio||''}
            onChange={(e) => {
                setUser({...User, bio:e.target.value, isDirty: true}); 
                setDatabaseStatusRequest("Sync");
            }}
            style={{ width: '100%', margin:"normal"}}
            readOnly= {(!IsSelf)}
            />
        </Box>

         
        <Box sx={{my:1}}>
        <TextField
            id="outlined-helperText"
            label="GitHub Link"
            value={User?.githubLink||''}
            onChange={(e) => {
                setUser({...User, githubLink:e.target.value, isDirty: true}); 
                setDatabaseStatusRequest("Sync");
            }}
            helperText=""
            margin="none"
            fullWidth
            InputProps={{
                readOnly: (!IsSelf),
            }}
        />
        </Box>

        <Box sx={{my:1}}>
        <TextField
            id="outlined-helperText"
            label="Linkedin Link"
            value={User?.LinkedinLink||''}
            onChange={(e) => {
                setUser({...User, LinkedinLink:e.target.value, isDirty: true}); 
                setDatabaseStatusRequest("Sync");
            }}
            helperText=""
            margin="none"
            fullWidth
            InputProps={{
                readOnly: (!IsSelf),
            }}
        />
        </Box>
    </Paper>
    }

    {NavigationValues.includes("Reviews") && 
        <ReviewPanel TargetId={User.uid??""} setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>
    }

    {NavigationValues.includes("Payment") &&
    <Paper elevation={0} 
    sx={{
        p: 2,
        my: 2,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5
    }}>

        <Box sx={{my:1}}>
            <Autocomplete
            value={User?.paymentMethod||''}
            onChange={async (event:any, newValue:any) => {
                if (newValue)
                {
                    newValue=capitalizeFirstLetter(newValue);

                    if(paymentMethods.find(paymentMethod=>paymentMethod.title==newValue))
                    {
                        console.log("paymentMethod exists");
                        setUser({...User, paymentMethod:newValue, isDirty:true}); 
                        setDatabaseStatusRequest("Sync");
                    }
                    else
                    {
                        let newPaymentMethod:PaymentMethodType = {title:newValue }
                        setUser({...User, paymentMethod:newValue, isDirty:true});
                        setPaymentMethods([...paymentMethods, newPaymentMethod]);
                        //await addDoc(collection(db, "PaymentMethods"), newPaymentMethod ).catch(()=>{setstatusText("We're sorry .. something went wrong.")});
                        //setDatabaseStatusRequest("Sync");
                    }    
                }
            }}
            filterOptions={(options:any, params:any) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option:any) => inputValue == option.title);
                if (inputValue !== '' && !isExisting) {
                filtered.push({
                    inputValue,
                    title: `Add a new Payment Method "${capitalizeFirstLetter(inputValue)}"`,
                });
                }
                return filtered;
            }}
            selectOnFocus
            handleHomeEndKeys
            id="paymentMethod"
            options={paymentMethods||[]}
            getOptionLabel={(option:any) => {
                // Value selected with enter, right from the input
                if (typeof option == 'string') {
                return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                return option.inputValue;
                }
                // Regular option
                return option.title;
            }}
            renderOption={(props, option:any) => <li {...props}>{option.title}</li>}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label="Payment Method" />
            )}
            fullWidth
            readOnly= {(!IsSelf)}
            />
        </Box>


        <Box sx={{my:1}}>
        <TextField
            id="outlined-helperText"
            label="Payment Method ID"
            value={User?.paymentMethodId||''}
            onChange={(e) => {
                setUser({...User, paymentMethodId:e.target.value, isDirty: true}); 
                setDatabaseStatusRequest("Sync");
            }}
            helperText="Account number, email, or other payment method ID"
            margin="none"
            fullWidth
            InputProps={{
                readOnly: (!IsSelf),
            }}
        />
        </Box>
    </Paper>
    }
</Container>
);
}