import { Box } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import { useEffect } from 'react';
import useState from 'react-usestateref';

export default function EffortFundDynLogo () {


    const [startAngle, setstartAngle, startAngleRef] = useState<number>(0);

    function logoAnimation()
    {
        if(startAngleRef.current == 0)
        {
            setstartAngle(360);
        }
        else if(startAngleRef.current == 360)
        {
            setstartAngle(0);
        }
    

    }

    const data = [
        {
          "id": "1",
          "label": "1",
          "value": 10,
          "color": "hsl(167, 70%, 50%)"
        },
        {
          "id": "2",
          "label": "2",
          "value": 10,
          "color": "hsl(109, 70%, 50%)"
        },
        {
          "id": "3",
          "label": "3",
          "value": 10,
          "color": "hsl(117, 70%, 50%)"
        },
        {
          "id": "4",
          "label": "4",
          "value": 10,
          "color": "hsl(257, 70%, 50%)"
        },
        {
          "id": "5",
          "label": "5",
          "value": 10,
          "color": "hsl(147, 70%, 50%)"
        }
      ];

      useEffect(
        ()=>{
            const interval = setInterval(async () => {
                logoAnimation();
            },10*1000);
            return () => clearInterval(interval);
        },[]);

        
    return ( 
        // install (please try to align the version of installed @nivo packages)
        // yarn add @nivo/pie

        // make sure parent container have a defined height when using
        // responsive component, otherwise height will be 0 and
        // no chart will be rendered.
        // website examples showcase many properties,
        // you'll often use just a few of them.

    <ResponsivePie
        data={data}
        startAngle={startAngleRef.current}
        endAngle={-360}
        innerRadius={0.7}
        padAngle={10}
        cornerRadius={15}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[]}
        fill={[]}
        legends={[]}
        isInteractive={false}
    />
    )  
}




