import { Box, Grid, Paper } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import { useEffect, useState } from 'react'


export default function CompensationChart ({cashEarned, cashHeld, sharesEarned, sharesHeld}:{cashEarned:number, cashHeld:number, sharesEarned:number, sharesHeld:number}) {

      const [CashData, setCashData] = useState<{
         id: string;
         label: string;
         value: number;
         color: string;
    }[]>(
    [
  {
    "id": "Cash to be Earned",
    "label": "Cash to be Earned",
    "value": cashEarned,
    "color": "hsl(347, 70%, 50%)"
  },
  {
    "id": "Cash to be Held",
    "label": "Cash to be Held",
    "value": cashHeld,
    "color": "hsl(264, 70%, 50%)"
  }
])

const [EquityData, setEquityData] = useState<{
  id: string;
  label: string;
  value: number;
  color: string;
}[]>(
[
{
"id": "Shares to be Earned",
"label": "Shares to be Earned",
"value": sharesEarned,
"color": "hsl(347, 70%, 50%)"
},
{
"id": "Shares to be Held",
"label": "Shares to be Held",
"value": sharesHeld,
"color": "hsl(264, 70%, 50%)"
}
])



useEffect(
  ()=>{
    setCashData(
      [
        {
          "id": "Cash to be Earned",
          "label": "Cash to be Earned",
          "value": cashEarned,
          "color": "hsl(347, 70%, 50%)"
        },
        {
          "id": "Cash to be Held",
          "label": "Cash to be Held",
          "value": cashHeld,
          "color": "hsl(264, 70%, 50%)"
        }
      ]
    )
    setEquityData(
      [
        {
          "id": "Shares to be Earned",
          "label": "Shares to be Earned",
          "value": sharesEarned,
          "color": "hsl(347, 70%, 50%)"
        },
        {
          "id": "Shares to be Held",
          "label": "Shares to be Held",
          "value": sharesHeld,
          "color": "hsl(264, 70%, 50%)"
        }
      ]
    )
  },[cashEarned, cashHeld, sharesEarned, sharesHeld]);
  
return(

  <Grid container spacing={0} sx={{}}>

  <Grid item  xs={12} md={12} lg={6} >
  <Box sx={{height:300, minWidth:300}}>

    <ResponsivePie
        data={CashData}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'Cash to be Earned'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'Cash to be Held'
                },
                id: 'lines'
            }
        ]}
    />
  </Box>
  </Grid>

  <Grid item  xs={12} md={12} lg={6} >
  <Box sx={{height:300, minWidth:300}}>

    <ResponsivePie
        data={EquityData}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'Shares to be Earned'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'Shares to be Held'
                },
                id: 'lines'
            }
        ]}

    />
  </Box>
  </Grid>
</Grid>

)


}