import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";  
import UserAvatar from "./UserAvatar";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export default function DropDownMenu({CurrentUserID, CurrentUserName}:{CurrentUserID:string, CurrentUserName:string})
{
    return (
        <Box>
            <Link style={{'textDecoration':"none"}} to={"/Home/User/"+CurrentUserID} >
                <Tooltip sx={{alignSelf:'center'}} title={CurrentUserName??""} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <div>
                    <UserAvatar userId={CurrentUserID!} userName={CurrentUserName} callContext="Messages"/>   
                </div>
                </Tooltip>
            </Link>
        </Box>
    );
}