import { Box, Typography } from "@mui/material";
import Logoimage from '../Image/logo4.png'; 
import EffortFundDynLogo from "./EffortFundDynLogo";

export default function LogoCompReduced()
{
    return (
        <Box sx={{m:2, display:"flex", flexDirection:'row'}}>

        <Box sx={{height:'40px', width:'40px', mt:1}}>
        <EffortFundDynLogo/>
        </Box>

        <Box sx={{display:"flex", flexDirection:'column'}}>
        <Typography fontFamily={'revert'}  fontWeight={'bolder'} color={'rgb(37, 150, 190)'} 
        sx={{textShadow:'1px 1px 1px', WebkitTextStroke:'0.5px black', fontSize:'25px'}}>EffortFund</Typography>
        <Typography fontFamily={'fantasy'} color={'black'} fontWeight={'normal'} sx={{ fontSize:'9px'}}>A Partnership Management Platform</Typography>
        </Box>
      </Box>
    );
}

