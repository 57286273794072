import { Box, Button, Paper, Slider, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import { DefaultLink, DefaultNode, ResponsiveSankey, SankeyMouseHandler } from '@nivo/sankey'
import { EquityComponentType, ProjectType, TransactionType, projectMembershipType } from './Interfaces';
import { useEffect } from 'react';
import useState from 'react-usestateref';


export default function InitEquityAssignmentChart({equityComps, projectMemberships, project
    ,Transactions, setTransactions}:
    {equityComps:EquityComponentType[], projectMemberships:projectMembershipType[], project:ProjectType,
        Transactions:TransactionType[], 
        setTransactions:React.Dispatch<React.SetStateAction<TransactionType[]>>, 
    }){

    const [SelectedTransaction, setSelectedTransaction, SelectedTransactionRef] = useState<TransactionType>();

    useEffect(
        ()=>{
            ResetTransactions();
        },[project, equityComps, projectMemberships]);

    useEffect(
        ()=>{
            console.log(Transactions);
        },[Transactions]);

        useEffect(
            ()=>{

            },[Transactions]);
    
            function ResetTransactions()
            {
                let transactionsBuffer: TransactionType[] = [];

                equityComps.forEach(comp => {

                    if(comp.type == 'outstanding')
                    {
                        let totalSharesPerComp = comp.targetEquityShares??0;
                        let sharesPerMemberInComp = Math.ceil((comp.targetEquityShares??0)/(projectMemberships.length));
        
                        projectMemberships.forEach(membership => {
        
                            let assignedShares = 0;
        
                            if(totalSharesPerComp > sharesPerMemberInComp)
                            {
                                assignedShares = sharesPerMemberInComp;
                                totalSharesPerComp -= sharesPerMemberInComp;
                            }
                            else
                            {
                                assignedShares = totalSharesPerComp;
                            }
        
                            let transaction:TransactionType;
 
                            transaction = 
                            {
                                date:new Date().toUTCString(), 
                                projectId: project.id!,
                                marketType:'primary',
                                equityComponent: comp.name,
                                firstParty:{
                                    Id: project.id!,
                                    type: "project",
                                    name: project.projectTitle
                                },
                                secondParty:{
                                    Id: membership.userId!,
                                    type: "user",
                                    name: membership.memberName
                                },

                                balancesheetEntryPairs:[{
                                    equityOrCash:'equity',
                                    firstEntry:{
                                        balanceSheetTarget:"project",
                                        assetOrLiability: 'liability',
                                        Id: project.id!,
                                        name: project.projectTitle,    
                                    },
                                    secondEntry:{
                                        balanceSheetTarget:"user",
                                        assetOrLiability: 'asset',
                                        Id: membership.userId!,
                                        name: membership.memberName,    
                                    },
                                    shareCount:assignedShares,
                                }],

                                status:'Confirmed',
                                valuation: project.initialValuation,
                                locked:false
                            }
                            transactionsBuffer.push(transaction);
    
                            }
                        )
                    }

                    else if(comp.type == 'cash')
                    {
                        let totalSharesPerComp = comp.targetEquityShares??0;
                        let sharesPerMemberInComp = Math.ceil((comp.targetEquityShares??0)/(projectMemberships.length));

                        let outstandingShares = equityComps.filter(comp=>(comp.type=="outstanding" || comp.type=="cash"))
                        .reduce((sum:number, value)=>{sum =sum+(value.targetEquityShares??0); return sum;}, 0);
                        let sharePrice = (project.initialValuation??0)/outstandingShares;
        
                        projectMemberships.forEach(membership => {

                            let assignedShares = 0;
        
                            if(totalSharesPerComp > sharesPerMemberInComp)
                            {
                                assignedShares = sharesPerMemberInComp;
                                totalSharesPerComp -= sharesPerMemberInComp;
                            }
                            else
                            {
                                assignedShares = totalSharesPerComp;
                            }

                            let transaction:TransactionType;
                            transaction = 
                            {
                                date:new Date().toUTCString(), 
                                projectId: project.id!,
                                marketType:'primary',
                                equityComponent: comp.name,

                                firstParty:{
                                    Id: project.id!,
                                    type: "project",
                                    name: project.projectTitle
                                },
                                secondParty:{
                                    Id: membership.userId!,
                                    type: "user",
                                    name: membership.memberName
                                },


                                balancesheetEntryPairs:[{
                                    equityOrCash: "cash",
                                    firstEntry:{
                                        balanceSheetTarget: "project",
                                        assetOrLiability: "asset",
                                        Id: project.id!,
                                        name: project.projectTitle,
                                    },
                                    secondEntry:{
                                        balanceSheetTarget: "user",
                                        assetOrLiability: "liability",
                                        Id: membership.userId!,
                                        name: membership.memberName,
                                    },
                                    cashValue: (assignedShares*sharePrice),
                                },
                                {
                                    equityOrCash: "equity",
                                    firstEntry:{
                                        balanceSheetTarget: "project",
                                        assetOrLiability: "liability",
                                        Id: project.id!,
                                        name: project.projectTitle,    
                                    },
                                    secondEntry:{
                                        balanceSheetTarget: "user",
                                        assetOrLiability: "asset",
                                        Id: membership.userId!,
                                        name: membership.memberName,
                                    },
                                    shareCount: assignedShares
                                }],
                                status:'Confirmed',
                                valuation: project.initialValuation,
                                locked:false
                            }
                            transactionsBuffer.push(transaction);
    
                        })
                    }
                })
                setTransactions(transactionsBuffer);
            }


            function UpdateTransactionEquityAssignment(setSharesValue:any)
            {
                let outstandingShares = equityComps.filter(comp=>(comp.type=="outstanding" || comp.type=="cash"))
                .reduce((sum:number, value)=>{sum =sum+(value.targetEquityShares??0); return sum;}, 0);
                let sharePrice = (project.initialValuation??0)/outstandingShares;

                setTransactions(Transactions.map(trans=>
                    {
                        if((trans.equityComponent) == (SelectedTransaction?.equityComponent) && 
                            (trans.firstParty.Id==SelectedTransaction!.firstParty.Id) && (trans.secondParty.Id==SelectedTransaction!.secondParty.Id))
                            {
                                let totalComponentShares = (equityComps.find(comp=>(comp.name == SelectedTransaction?.equityComponent))?.targetEquityShares??0);
                                let totalassignedComponentShares = (Transactions.filter(trans=>(trans.equityComponent == SelectedTransaction?.equityComponent))
                                .reduce((sum:number, value)=>{sum =sum+(value.balancesheetEntryPairs.find(pair=>(pair.equityOrCash=="equity"))?.shareCount??0); return sum;}, 0))

                                let sharesAssignedToSelectedTransaction = SelectedTransaction!.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.shareCount??0;                                
                                let UnallocatedShares = totalComponentShares - totalassignedComponentShares;
                                let AssignmentRequest = parseInt(setSharesValue.target.value);
                                let maxAssignment = UnallocatedShares + sharesAssignedToSelectedTransaction;

                                trans.balancesheetEntryPairs.map(entry=>
                                {
                                    if(entry.equityOrCash=="equity")
                                    {
                                        if(AssignmentRequest <= maxAssignment)
                                        {        
                                            entry.shareCount = AssignmentRequest;
                                        } 
                                        else
                                        {
                                            entry.shareCount = maxAssignment;
                                        }
                                    }

                                    else if(entry.equityOrCash=="cash")
                                    {
                                        if(AssignmentRequest <= maxAssignment)
                                        {        
                                            entry.cashValue = (AssignmentRequest*sharePrice);
                                        } 
                                        else
                                        {
                                            entry.cashValue = (maxAssignment*sharePrice);
                                        }    
                                    }
                                    return entry;
                                }
                                )
                            }
                            return trans;
                    }
                ))
            }

    return(
        <Paper elevation={0} 
        
        sx={{
            py: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            width: '100%',
        }}>
            <Typography variant="caption" sx={{textAlign:"center"}}>
                Click on an assignment link to change its allocated shares.
            </Typography>

            {SelectedTransaction&&
            <Typography 
                variant="caption" 
                sx={{fontSize:'min(3vw,15px)', mx:'5vw', my:2, opacity:0.8, width:'60%', alignSelf:'center'}}
            >
            Out of 
            <strong> {equityComps.find(comp=>(comp.name == SelectedTransaction?.equityComponent))?.targetEquityShares} </strong>
            Shares assigned to the 
            <strong> {SelectedTransaction?.equityComponent} </strong>
            equity component, 
            <TextField 
            inputProps={{ style: { textAlign: 'center' }}} 
            size="small" 
            value={SelectedTransaction?.balancesheetEntryPairs.find(entryPair=>(entryPair.equityOrCash=="equity"))?.shareCount} 
            id="standard-basic" 
            label="Shares" 
            variant="standard" 
            type="number" 
            sx={{position:"inherit", width:'80px', mx:0.5, mt:-2.5}} 
            onChange={(value)=>{UpdateTransactionEquityAssignment(value)}}
            /> Shares are allocated to 
            <strong> {SelectedTransaction?.secondParty.name} </strong>, 
            this represnets 
            <strong> {Math.round((SelectedTransaction?.balancesheetEntryPairs.find(entryPair=>(entryPair.equityOrCash=="equity"))?.shareCount??0)/(equityComps.find(comp=>(comp.name == SelectedTransaction?.equityComponent))?.targetEquityShares??1)*10000)/100} </strong>
            % of the equity component's shares.
            </Typography>
            }
            {SelectedTransaction?.equityComponent=="Cash"&&
            <Typography 
                variant="caption" 
                sx={{fontSize:'min(3vw,15px)', mx:'5vw', my:2, opacity:0.8, width:'60%', alignSelf:'center'}}
            >
                In exchange for the equity, 
                <strong> {SelectedTransaction.secondParty.name} </strong>
                will contribute 
                <strong> {SelectedTransaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="cash"))?.cashValue?.toFixed(2)} {project.currency} </strong>
                to the project's balance sheet, that is considering a Valuation of 
                <strong> {SelectedTransaction.valuation} {project.currency}</strong>
                , 
                <strong> {equityComps.filter(comp=>(comp.type=="outstanding" || comp.type=="cash"))
                .reduce((sum:number, value)=>{sum =sum+(value.targetEquityShares??0); return sum;}, 0)} </strong>
                Outstanding Shares, and a Share Price of 
                <strong> {((project.initialValuation??0)/(equityComps.filter(comp=>(comp.type=="outstanding" || comp.type=="cash"))
                .reduce((sum:number, value)=>{sum =sum+(value.targetEquityShares??0); return sum;}, 0))).toFixed(2)} {project.currency}.</strong>

            </Typography>
        
            }
            
        <Box width='25%' alignSelf={"center"} sx={{display:"flex", flexDirection:'row', mt:2, justifyContent:'space-evenly'}}>
            <Button sx={{textTransform: 'none', color:'black', backgroundColor:'#DCDCDC'}} variant="contained" onClick={()=>ResetTransactions()}>Reset</Button>
        </Box>

        <Box sx={{height:600, width:'100%'}} >

            <ResponsiveSankey
                data={{
                    "nodes": 
                    (equityComps
                    .filter(comp=>(comp.type=='outstanding'||comp.type=='cash'))
                    .filter(comp=>
                    (
                        (comp.targetEquityShares??0) > 
                        (Transactions.filter(trans=>(trans.equityComponent == comp.name))
                        .reduce((sum:number, value)=>{sum =sum+(value.balancesheetEntryPairs.find(pair=>(pair.equityOrCash=="equity"))?.shareCount??0); return sum;}, 0))
                    ) 
                    ).length == 0)
                    ?
                    (equityComps.filter(comp=>(comp.type=='outstanding'||comp.type=='cash')).map((comp) => ({"id": comp.name} as DefaultNode)).concat(
                    projectMemberships.map((member) => ({"id": member.memberName} as DefaultNode)))) 
                    : 
                    (equityComps.filter(comp=>(comp.type=='outstanding'||comp.type=='cash')).map((comp) => ({"id": comp.name} as DefaultNode)).concat(
                        projectMemberships.map((member) => ({"id": member.memberName} as DefaultNode))).concat(({"id": "Unallocated"} as DefaultNode)) ) 
                    ,

                    
                    "links": Transactions.map(transaction => 
                        ({"source": (transaction.secondParty.type=='user')?transaction.secondParty.name:"", 
                            "target": transaction.equityComponent, 
                            "value":(transaction.balancesheetEntryPairs.find(entry=>(entry.equityOrCash=="equity"))?.shareCount), 
                            "startColor":(
                                (transaction.equityComponent == SelectedTransaction?.equityComponent) && 
                                (transaction.firstParty.Id == SelectedTransaction?.firstParty.Id) && 
                                (transaction.secondParty.Id == SelectedTransaction?.secondParty.Id) 
                            ) ? null: 'darkgrey',
                            "endColor":(
                                (transaction.equityComponent == SelectedTransaction?.equityComponent) && 
                                (transaction.firstParty.Id == SelectedTransaction?.firstParty.Id) && 
                                (transaction.secondParty.Id == SelectedTransaction?.secondParty.Id) 
                            ) ? null: 'darkgrey'
                        } as DefaultLink))
                        .concat(equityComps
                        .filter(comp=>(comp.type=='outstanding'||comp.type=='cash'))
                        .filter(comp=>
                            (
                                (comp.targetEquityShares??0) 
                                > 
                                (Transactions.filter(trans=>(trans.equityComponent == comp.name))
                                .reduce((sum:number, value)=>{sum =sum+(value.balancesheetEntryPairs.find(pair=>(pair.equityOrCash=="equity"))?.shareCount??0); return sum;}, 0))
                            ))
                        .map(comp=>{
                                    return({"source": "Unallocated", "target": comp.name, 
                                        "value":((comp.targetEquityShares??0) - (Transactions.filter(trans=>(trans.equityComponent == comp.name)).reduce((sum:number, value)=>{sum =sum+(value.balancesheetEntryPairs.find(pair=>(pair.equityOrCash=="equity"))?.shareCount??0); return sum;}, 0))), 
                                        "startColor":'darkgrey',
                                        "endColor":'darkgrey',} as DefaultLink)    
                    }))
                }}

                onClick={(node:any) => 
                    {
                        setSelectedTransaction(Transactions.find(trans=>((trans.secondParty.name == node.source.label) && (trans.secondParty.type=="user") && (trans.equityComponent == node.target.label))));
                    }
                }
                
                margin={{ top: 40, right: 50, bottom: 40, left: 50 }}
                align="justify"
                colors={{ scheme: 'category10' }}
                nodeOpacity={1}
                nodeHoverOthersOpacity={0.35}
                nodeThickness={18}
                nodeSpacing={24}
                nodeBorderWidth={0}
                nodeBorderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.8
                        ]
                    ]
                }}
                nodeBorderRadius={3}
                linkOpacity={0.5}
                linkHoverOthersOpacity={0.1}
                linkContract={3}
                enableLinkGradient={true}
                labelPosition="outside"
                labelOrientation="vertical"
                labelPadding={16}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1
                        ]
                    ]
                }}
            />
        </Box>
    </Paper>
    );
}