import { Avatar, Box } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { auth, storage } from "../firebase";
import { getBlob, ref } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";

export default function UserAvatar({userId, userName, callContext}:{userId:string, userName:string, callContext:string}) {

    const [user, loading, error] = useAuthState(auth);
    // let currentUserId = user?.uid;
    // let currentUserName = user?.displayName??"";

    const [profilePicture, setProfilePicture] = useState<Blob>({} as Blob);
    const [PPURL, setPPURL] = useState<string>("");


    useEffect(() =>{
        let PPath:string="profiles/"+userId+"/pp.jpg";
        const profileImagesRef = ref(storage, PPath);
        console.log(profileImagesRef);
        getBlob(profileImagesRef)
        .then((blob) => {console.log(blob); setProfilePicture(blob);} )
        .catch((error) => {
            console.log(error);
            setProfilePicture(new Blob());
        })

        console.log("reloaded");
    },
    [userId]
    );

    useEffect(() =>{
        if(profilePicture.size>0)
        {
            setPPURL(URL.createObjectURL(profilePicture));
        }
        else
        {
            setPPURL("");
            console.log("no profile picture");
        }
    },
    [profilePicture]
    );

return (

    <div>
    {(callContext=="UserAccount") &&
    <Avatar 
    sx={{":hover": {boxShadow: 15}, 
    boxShadow: 3, 
    alignSelf:'center', 
    height:'20vw', 
    width:'20vw', 
    fontSize:'15vw',
    bgcolor: blue[200] }}
    alt={userName}
    src={PPURL}
    />}

    {(callContext=="Messages") &&
    <Avatar sx={{bgcolor: blue[200] }}
    //alt={userName}
    src={PPURL}
    /> }
    
    </div>

    );
}
