import * as React from 'react';
import { styled, createTheme, ThemeProvider, makeStyles, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
//import Link from '@mui/material/Link';
import { Link } from "react-router-dom";  

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './listItems';
import CashDisplay from './CashDisplay';
import Tasks from './Tasks'
import { Route, Switch, Redirect, useRouteMatch, BrowserRouter, useHistory } from 'react-router-dom';
//import EquityPieChart from './EquityPieChart';
import Projects from './Projects';
import Project from './Project';
import CreateOrEditTask from './CreateOrEditTask';
import User from './User';
import { ConnectionStatusType, ErrorLogType, UserType } from './Interfaces';
import Dashboard from './Dashboard';
import { CompatRoute, CompatRouter } from 'react-router-dom-v5-compat';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../firebase';
import Reports from './Reports';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Backdrop, Fab, Tooltip } from '@mui/material';
import useWindowDimensions from './useWindowDimensions';
import Task from './Task';
import UserAvatar from './UserAvatar';
import CreateOrEditProject from './CreateOrEditProject';
import CreateProjectSwitch from './CreateProjectSwitch';
import CloudStatus from './CloudStatus';
import Title from './Title';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DropDownMenu from './DropDowmMenu';
import LogoCompReduced from './LogoCompReduced';
import RegisterPanel from './RegisterPanel';
import { getAuth, onAuthStateChanged } from 'firebase/auth';



export default function Home() {

  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  let currentUserId = user?.uid;
  let currentUserName = user?.displayName??"";
  if(user?.isAnonymous)
  {
    currentUserName = "Guest";
  }
  

  const [DatabaseStatusRequest, setDatabaseStatusRequest] = React.useState<ConnectionStatusType>("None");
  const [DatabaseErrorDesc, setDatabaseErrorDesc] = React.useState<ErrorLogType>({message:''} as ErrorLogType);

  const [selectedPanel, setselectedPanel] = React.useState<string>("Dashboard");

  const history = useHistory();
  
  React.useEffect(() => {
  if (!user)
  {
    history.push("/");
  } 
  }, [user]);

  




  return (
        <Box
          sx={{
            backgroundColor: "#EEF5FF",
            display:'flex',
            flexGrow: 1,
            flexDirection:'column',
            width: '100%',
            height: '100%',
          }}
        >

        <Box
          sx={{
            backgroundColor: "inherit",
            display:'flex',
            flexDirection:'row',
            mx:5,
            my:3,
            justifyContent:'space-between',
          }}
        >
            <LogoCompReduced/>

            <Box
            sx={{
            backgroundColor: "inherit",
            display:'flex',
            flexDirection:'row',
            mt:2
            }}>
            <CloudStatus DatabaseStatusRequest={DatabaseStatusRequest} DatabaseErrorDesc={DatabaseErrorDesc!}/>

            <DropDownMenu CurrentUserID={currentUserId??""} CurrentUserName={currentUserName}/>

            <Tooltip sx={{alignSelf:'center'}} title="Sign out" enterTouchDelay={0} leaveTouchDelay={5000} arrow>
            <Fab sx={{mx:3, mb:2}} size='small' color="info" aria-label="add" onClick={()=>{ logout();}}>
            <ExitToAppIcon/>
            </Fab>
            </Tooltip>
            </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "inherit",
            boxShadow: 1,
            ":hover": {boxShadow: 3},
            display:'flex',
            flexDirection:'row',
            mx:5,
            my:3,
            justifyContent:'space-evenly',
            width:'75%',
            alignSelf:'center'
          }}
        >
            <IconButton color={selectedPanel=="Dashboard"? 'primary': "default"} onClick={()=>{setselectedPanel("Dashboard")}} 
            component={(props:any) => <Link {...props} to="/Home/Dashboard" />} >
              <Box >
              <DashboardIcon />
              <Typography sx={{fontSize:'min(3vw,20px)'}} >Dashboard</Typography>
              </Box>
            </IconButton>

            <IconButton disabled={user?.isAnonymous} color={selectedPanel=="Accounts"? 'primary': "default"} onClick={()=>{setselectedPanel("Accounts")}}
            component={(props:any) => <Link {...props} to={"/Home/User/"+currentUserId} />}>
              <Box>
              <AccountCircleIcon />
              <Typography sx={{fontSize:'min(3vw,20px)'}} >Accounts</Typography>
              </Box>
            </IconButton>

            <IconButton color={selectedPanel=="Projects"? 'primary': "default"} onClick={()=>{setselectedPanel("Projects")}} 
            component={(props:any) => <Link {...props} to="/Home/Projects"/>} >
              <Box>
              <AccountTreeIcon /> 
              <Typography sx={{fontSize:'min(3vw,20px)'}} >Projects</Typography>
              </Box>
            </IconButton>

            <IconButton color={selectedPanel=="Tasks"? 'primary': "default"} onClick={()=>{setselectedPanel("Tasks")}}
            component={(props:any) => <Link {...props} to="/Home/Tasks"/>} >
              <Box>
              <FormatListBulletedIcon />
              <Typography sx={{fontSize:'min(3vw,20px)'}} >Tasks</Typography>
              </Box>
            </IconButton>

            <IconButton color={selectedPanel=="Reports"? 'primary': "default"} onClick={()=>{setselectedPanel("Reports")}}
            component={(props:any) => <Link {...props} to="/Home/Reports"/>} >
              <Box>
              <BarChartIcon />
              <Typography sx={{fontSize:'min(3vw,20px)'}} >Reports</Typography>
              </Box>
            </IconButton>

        </Box>

        <Route exact path="/Home">
          <Redirect to="/Home/Dashboard" />
        </Route>

        <Route path="/Home/Dashboard">
          <Dashboard/>
        </Route>

        <Route exact path="/Home/Projects">
          <Projects
          setDatabaseStatusRequest={setDatabaseStatusRequest} 
          setDatabaseErrorDesc={setDatabaseErrorDesc}/>
        </Route>

        <Route exact path="/Home/CreateProject">
          <CreateProjectSwitch 
          setDatabaseStatusRequest={setDatabaseStatusRequest} 
          setDatabaseErrorDesc={setDatabaseErrorDesc}/>
        </Route>

        <Route exact path="/Home/Project/:id">
          <Project
          setDatabaseStatusRequest={setDatabaseStatusRequest} 
          setDatabaseErrorDesc={setDatabaseErrorDesc}/>
        </Route>

        <Route exact path="/Home/EditProject/:id">
          <CreateOrEditProject IsStoryNotQuick={false} IsCreateNotEdit={false} 
          setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>
        </Route>

        <Route exact path="/Home/EditTask/:id">
          <CreateOrEditTask IsCreateNotEdit={false} setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>
        </Route>

        <Route exact path="/Home/Task/:id">
          <Task setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>
        </Route>

        <Route exact path="/Home/CreateTask">
          <CreateOrEditTask IsCreateNotEdit={true} setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>
        </Route>

        <Route exact path="/Home/User/:id">
          <User setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>
        </Route>

        <Route exact path="/Home/Tasks"> 
              <Tasks />
        </Route>

        <Route exact path="/Home/Reports">
            <Reports/>
        </Route>
          
        {user?.isAnonymous&&  
        <Box sx={{position:'fixed', alignSelf:'center', bottom:'10px', backgroundColor:'white', p:1, width:'90vw',
         opacity:0.5, ":hover":{opacity:1}
        }}>
          <Typography color={'black'} sx={{mb:0.5, mx:2}}>You're logged in as a guest, complete registeration to save your session activities.</Typography>
          <RegisterPanel context='Guest'/>
        </Box>
        }

        </Box>
  );

}