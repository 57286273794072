import { Paper, Typography } from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, storage } from '../firebase';
import LoadingButton from '@mui/lab/LoadingButton';

export default function FileDropZone({closeDialog}:{closeDialog:()=>void}) {

  const [user, loading, error] = useAuthState(auth);
  let currentUserId = user?.uid;
  let currentUserName = user?.displayName??"";

  const [uploading, setUploading] = useState<boolean>(false);
  const [message, setmessage] = useState<string>("Drag and drop picture, or click to select from folder.");


  function uploadFile(file:File) {

    let PPath:string="profiles/"+currentUserId+"/pp.jpg";
    const profileImagesRef = ref(storage, PPath);

    uploadBytes(profileImagesRef, file).then((snapshot) => {
      console.log('Uploaded a blob or file!');
      setUploading(false);
      closeDialog();
    });
    
  }


  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file:File) => {
      const reader = new FileReader()

      reader.onloadstart = () => { }
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result

        if(file.type!=="image/jpeg" && file.type!=="image/png")
        {
          setmessage("Please upload a jpeg or png file.");
        }
        else if(file.size>2000000)
        {
          setmessage("File size must be less than 2MB.");
        }
        else
        {
          setmessage("Uploading...");
          setUploading(true);
          uploadFile(file)
        }
      }
      reader.readAsArrayBuffer(file)
    })
    
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Paper elevation={3} 
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'canter',
                justifyContent:'center',
                backgroundColor: 'lightgray',
                ":hover": {boxShadow: 15},
                minHeight: '50vh',
                minWidth: '50vw'
            }}>

        <Typography sx={{alignSelf:'center', opacity:0.5}} >{message}</Typography>
        
        <LoadingButton
          size="large"
          loading={uploading}
          fullWidth
        />

      </Paper>
    </div>
  )
}
