import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { TaskType } from './Interfaces';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';



export default function TasksTable({Tasks}:{Tasks:TaskType[]}) {



    return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Task Title</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">Project</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">Deadline</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">Assignee</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">Skill</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {Tasks?.map((task) => (
              <TableRow component={props => <Link {...props} to={"/Home/Task/"+task.id} />} key={task.id}>
                <TableCell >{task?.taskTitle}</TableCell>
                <TableCell>{task?.project?.projectName}</TableCell>
                <TableCell>{task?.deadline}</TableCell>
                <TableCell>{task?.assignee?.assigneeName}</TableCell>
                <TableCell>{task?.skill}</TableCell>
                <TableCell >{task?.status}</TableCell>
              </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      );
    }