import { Card, CardContent, CardHeader, Container, Grid, Paper } from "@mui/material";
import { blue, lightBlue } from "@mui/material/colors";
import { useEffect, useState } from "react";
import Title from "./Title";

export default function NeededSkills({SkillList}:{SkillList:String[]})
{
    return(
        
        <Paper elevation={0} 
                sx={{
                p: 2,
                my: 2,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 5
                }}
        >

            <Title>Needed Skills</Title>

            {SkillList!==[]&&
            <Grid container spacing={3}>
            {SkillList.map(skill => (
                <Grid item xs={12} md={6} lg={2}>
                    <Card sx={{fontSize:15, borderRadius: 5, backgroundColor:'#BCDBFF', ':hover': {boxShadow: 15, backgroundColor:'white'}, color:'black'}} elevation={3} >  
                        <CardHeader sx={{fontSize:15}} subheader={skill} />
                    </Card>
                </Grid>
            ))}
            </Grid>
            }
        </Paper>   
    );
}