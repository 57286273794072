import { Box, Button, ButtonGroup, Container, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Paper, Rating, Select, Table, TableBody, TableCell, TableRow, TextareaAutosize, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, Dialog, DialogContent, Slide } from "@mui/material";
import Title from "./Title";
import React, { useEffect } from "react";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from "@mui/lab";
import {TaskType, UserType, ProjectType, RatingItem, projectMembershipType, TransactionType, TaskStatusType, ErrorLogType, RequestOrInvitationType} from './Interfaces';
import { Link, useHistory, useParams } from "react-router-dom";
import {auth, db} from "../firebase"
import Switch from '@mui/material/Switch';
//import { typography } from "@mui/system";
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useGlobalState as useFirebaseGlobalState } from "./Settings" ;
import { useAuthState } from "react-firebase-hooks/auth";
import useState from 'react-usestateref';
import { Network } from "@nivo/network";
import NetworkChart from "./StateChart";
import StateChart from "./StateChart";
import TaskTimelineChart from "./TaskTimelineChart";
import DescriptionPanel from "./TaskDescriptionPanel";
import CommentSection from "./CommentSection";
import { Navigation } from "@mui/icons-material";
import useWindowDimensions from "./useWindowDimensions";
import CalendarLog from "./CalendarLog";
import ReviewPanel from "./ReviewPanel";
import { TransitionProps } from "@mui/material/transitions";
import TaskConclude from "./TaskConclude";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


export default function Task({setDatabaseStatusRequest, setDatabaseErrorDesc}:
    {setDatabaseStatusRequest:Function, setDatabaseErrorDesc:Function})
{
    const history = useHistory();
    
    let { id } = useParams<{id: string}>();

    const [task, setTask, TaskRef] = useState<TaskType>({});
    const [Projects, setProjects] = useState<ProjectType[]>([]);
    const [ProjectsMemberships, setProjectsMemberships] = useState<projectMembershipType[]>();
    const [TaskRequests, setTaskRequests, TaskRequestsRef] = useState<RequestOrInvitationType[]>([]);
    const [TaskInvitations, setTaskInvitations, TaskInvitationsRef] = useState<RequestOrInvitationType[]>([]);
    //const [selectedProject, setselectedProject] = useState<ProjectType>();
    const [AssigneeUser, setAssigneeUser] = useState<UserType>();
    const [newTransactions, setNewTransactions] = useState<TransactionType[]>([]);
    const [NavigationValues, setNavigationValues] = React.useState<string[]>(() => ["Details"]);


    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";

    const [openTaskConcludePanel, setopenTaskConcludePanel] = React.useState(false);

    useEffect(() =>{
        currentUserId = user?.uid;
        currentUserName = user?.displayName??"";
    },
    [user]
    );

    const [useFirebase] = useFirebaseGlobalState('useFirebase');
    const IsAuthor = task?.author?.authorId==currentUserId ? true:false;
    const IsAssignee = task?.assignee?.assigneeId==currentUserId ? true:false;


    let IsInvited:boolean = false;
    if(TaskInvitations?.find(invitation => (invitation.MemberId == currentUserId))?.status == ("active") && task.status=="Unassigned")
    {IsInvited=true;}

    let IsAcceptedInvitation:boolean = false;
    if(TaskInvitations?.find(invitation => (invitation.MemberId == currentUserId))?.status == ("accepted") && task.status=="Unassigned")
    {IsAcceptedInvitation=true;}
    
    let IsMember:boolean = false;
    if(ProjectsMemberships?.find(member => (member.userId == currentUserId))?.status == "active")
    {IsMember=true;}

    let helpMessage = "";
    let helpDescription = "";

    if(IsAuthor)
    {
        helpMessage = "Task Author";
        helpDescription = "You are the author of this task, you may edit the task while unassigned.";
    }
    else if(IsAssignee)
    {
        helpMessage = "Task Assignee";
        helpDescription = "You are the current assignee of this task, you may move the task's status forward.";
    }
    else if(IsAcceptedInvitation)
    {
        helpMessage = "Invitation Accepted";
        helpDescription = "You've accepted the invitation to perform this task, the task author can now select the assignee from the list of accepted invitations.";
    }
    else if(IsInvited)
    {
        helpMessage = "Invited Member";
        helpDescription = "You've been invited to perform this task, you may accept or decline the invitation.";
    }
    else if(IsMember)
    {
        helpMessage = "Project Member";
        helpDescription = "You are a member of this task's project, you may request the task or rate it when In Review.";
    }
    else if(!IsMember)
    {
        helpMessage = "Not A Project Member";
        helpDescription = "You are not a member of this task's project.";
    }

    function HandleAssignMember(memberId:string, memberName:string)
    {
        setTask({...task, assignee:{assigneeId:memberId, assigneeName:memberName}, status:"Active", isDirty:true});
        setDatabaseStatusRequest("Sync");
    }  

    function HandleAcceptInvitation(memberId:string)
    {
        setTaskInvitations(
            TaskInvitations?.map(invitation=>{
                if(invitation.MemberId==memberId)
                {
                    invitation.status="accepted";
                    invitation.isDirty=true;
                }
                return invitation;
            })
        );
        setDatabaseStatusRequest("Sync");
    }  

    function HandleDeclineInvitation(memberId:string)
    {
        setTaskInvitations(
            TaskInvitations?.map(invitation=>{
                if(invitation.MemberId==memberId)
                {
                    invitation.status="declined";
                    invitation.isDirty=true;
                }
                return invitation;
            })
        );
        setDatabaseStatusRequest("Sync");
    }  

    const handleRequestTask = () => {

        //Create new request
        if(!TaskRequests.find(request=>( (request.MemberId==currentUserId) && (request.TaskId==id) )))
        {
            let requestItem:RequestOrInvitationType={MemberId:currentUserId, MemberName:currentUserName,
                 TaskId:id, status:"active", isDirty:true}
            setTaskRequests([...TaskRequests, requestItem]);
            setDatabaseStatusRequest("Sync");
        }
        //Modify old request
        else
        {
            setTaskRequests(
                TaskRequests?.map(requestItem=>
                    {
                        if(requestItem.MemberId==currentUserId && requestItem.TaskId==id)
                        {
                            if(requestItem.status=="active")
                            {
                                requestItem.status="disabled";
                            }
                            else if(requestItem.status=="disabled")
                            {
                                requestItem.status="active";
                            }
                            requestItem.isDirty = true;
                            setDatabaseStatusRequest("Sync");
                        }
                        return requestItem;
                    }));
        }
    }

    const handleInviteToTask = (userId:string, userName:string) => {
        console.log(userId, userName)
        //Create new invitation
        if(!TaskInvitations.find(invitation=>( (invitation.MemberId==userId) && (invitation.TaskId==id) )))
        {
            let inviteItem:RequestOrInvitationType={MemberId:userId, MemberName:userName,
                 TaskId:id, status:"active", isDirty:true}
            setTaskInvitations([...TaskInvitations, inviteItem]);
            setDatabaseStatusRequest("Sync");
        }
        //Modify old invitation
        else
        {
            setTaskInvitations(
                TaskInvitations?.map(inviteItem=>
                    {
                        if(inviteItem.MemberId==userId && inviteItem.TaskId==id)
                        {
                            if(inviteItem.status=="active")
                            {
                                inviteItem.status="disabled";
                            }
                            else if(inviteItem.status=="disabled")
                            {
                                inviteItem.status="active";
                            }
                            inviteItem.isDirty = true;
                            setDatabaseStatusRequest("Sync");
                        }
                        return inviteItem;
                    }));
        }
    }
    
    function loadDB()
    {
        const fetchProjects = async () => {
        const querySnapshot = await getDocs(collection(db, "Projects"));
        let Projects:ProjectType[]=[];
        querySnapshot.docs.forEach(doc=>{
            let Project:ProjectType;
            Project=doc.data() as ProjectType;
            Project.id = doc.id;
            Project.isDirty = false;
            Projects.push(Project);
        })
        setProjects(Projects)
        }
        fetchProjects().catch((e)=>{ErrorHandler(e, "fetchProjects");}).then(()=>{setDatabaseStatusRequest("OK");});


        const fetchTask = async () => {
        const docRef = doc(db, "Tasks", id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            let Task:TaskType;
            Task=docSnap.data();
            Task.id = docSnap.id;
            Task.isDirty = false;
            setTask(Task);
        } else {
            // doc.data() will be undefined in this case
        }
        }
        fetchTask().catch((e)=>{ErrorHandler(e, "fetchTask");}).then(()=>{setDatabaseStatusRequest("OK");});

        const fetchTaskRequests = async () => {
            const q = query(collection(db, "TaskRequests"), where("TaskId", "==", id));
            const querySnapshot = await getDocs(q);
              let TaskRequests:RequestOrInvitationType[]=[];
              querySnapshot.docs.forEach(doc=>{
                let TaskRequest:RequestOrInvitationType;
                TaskRequest=doc.data();
                TaskRequest.id = doc.id;
                TaskRequest.isDirty = false;
                TaskRequests.push(TaskRequest);
              })
              setTaskRequests(TaskRequests)
            }
          fetchTaskRequests().catch((e)=>{ErrorHandler(e, "Fetch Task Requests")}).then(()=>{setDatabaseStatusRequest("OK");});

          const fetchTaskInvitations = async () => {
            const q = query(collection(db, "TaskInvitations"), where("TaskId", "==", id));
            const querySnapshot = await getDocs(q);
              let TaskInvitations:RequestOrInvitationType[]=[];
              querySnapshot.docs.forEach(doc=>{
                let TaskInvitation:RequestOrInvitationType;
                TaskInvitation=doc.data();
                TaskInvitation.id = doc.id;
                TaskInvitation.isDirty = false;
                TaskInvitations.push(TaskInvitation);
              })
              setTaskInvitations(TaskInvitations)
            }
          fetchTaskInvitations().catch((e)=>{ErrorHandler(e, "Fetch Task Invitations")}).then(()=>{setDatabaseStatusRequest("OK");});
      }
      
    function loadDBPostTaskLoad()
    {

        if(AssigneeUser==undefined && task.assignee?.assigneeId!==undefined)
        {
            const fetchUser = async () => {
            const docRef = doc(db, "Users", task.assignee?.assigneeId??"");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let User:UserType;
                User=docSnap.data();
                setAssigneeUser(User);
            } else {
                // doc.data() will be undefined in this case
            }
            }
            fetchUser().catch((e)=>{ErrorHandler(e, "fetchUsers");}).then(()=>{setDatabaseStatusRequest("OK");});
        }

        if(task.project?.projectID!==undefined)
        {
            const fetchProjectsMemberships = async () => {
                const q = query(collection(db, "ProjectsMemberships"), where("projectId", "==", task.project?.projectID));
                const querySnapshot = await getDocs(q);
                  let projectsMemberships:projectMembershipType[]=[];
                  querySnapshot.docs.forEach(doc=>{
                    let projectMembership:projectMembershipType;
                    projectMembership=doc.data() as projectMembershipType;
                    projectMembership.id = doc.id;
                    projectsMemberships.push(projectMembership);
                  })
                  setProjectsMemberships(projectsMemberships)
                }
            fetchProjectsMemberships().catch((e)=>{ErrorHandler(e, "fetchProjectMemberships");}).then(()=>{setDatabaseStatusRequest("OK");});
        }
    }


    useEffect(
        ()=>{
            loadDB();
            const interval = setInterval(() => {
                saveDBEdit();
            },2*1000);
            return () => clearInterval(interval);
        },[]);


    useEffect(loadDBPostTaskLoad,[task]); 


    async function saveDBEdit()
    {
        let activeCycle=false;

        if(TaskRef.current.isDirty)
        {
            activeCycle=true;
            await setDoc(doc(db, "Tasks", id), TaskRef.current).then(()=>{setTask({...TaskRef.current, isDirty:false});})
            .catch((e)=>{ErrorHandler(e, "UpdateTask");}).then(()=>{setDatabaseStatusRequest("OK");});
        }

        TaskRequestsRef.current.forEach(async (request,i)=>{
            if(request.isDirty==true)
            {   
                activeCycle=true;
                if(request.id==undefined)
                {
                    await addDoc(collection(db, "TaskRequests"), request ).then((docRef)=>{
                        setTaskRequests(TaskRequestsRef.current?.map((request,j)=>{if(i==j){request.isDirty=false; request.id=docRef.id}return request;})
                    )}).catch((e)=>{ErrorHandler(e, "AddTaskRequest");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
                else
                {
                    console.log(request);
                    await setDoc(doc(db, "TaskRequests", request.id??""), request).then(()=>{
                        setTaskRequests(TaskRequestsRef.current?.map((request,j)=>{if(i==j){request.isDirty=false;}return request;})
                    )}).catch((e)=>{ErrorHandler(e, "UpdateTaskRequest");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
            }
        })

        TaskInvitationsRef.current.forEach(async (invitation,i)=>{
            if(invitation.isDirty==true)
            {   
                activeCycle=true;
                if(invitation.id==undefined)
                {
                    await addDoc(collection(db, "TaskInvitations"), invitation ).then((docRef)=>{
                        setTaskInvitations(TaskInvitationsRef.current?.map((invitation,j)=>{if(i==j){invitation.isDirty=false; invitation.id=docRef.id}return invitation;})
                    )}).catch((e)=>{ErrorHandler(e, "AddTaskInvitation");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
                else
                {
                    console.log(invitation);
                    await setDoc(doc(db, "TaskInvitations", invitation.id??""), invitation).then(()=>{
                        setTaskInvitations(TaskInvitationsRef.current?.map((invitation,j)=>{if(i==j){invitation.isDirty=false;}return invitation;})
                    )}).catch((e)=>{ErrorHandler(e, "UpdateTaskInvitation");}).then(()=>{setDatabaseStatusRequest("OK");});
                }
            }
        })

        if(activeCycle==false)
        {
            let status="";
            if(TaskRef.current.isDirty==true)
            {
                setDatabaseStatusRequest("DatabaseError");
            }
            else if(TaskRequestsRef?.current.some(request=>request.isDirty==true))
            {
                setDatabaseStatusRequest("DatabaseError");
            }
            else if(TaskInvitationsRef?.current.some(invitation=>invitation.isDirty==true))
            {
                setDatabaseStatusRequest("DatabaseError");
            }
        }
    }

    function HandleReset(e:any)
    {
        loadDB();
    }


    function allowedStatus(currentState:string)
    {
        let statusList:string[] = [];
        if(currentState == "Unassigned")
        {
            statusList = ["Unassigned", "Active"];
        }
        else if (currentState == "Active")
        {
            statusList = ["Active", "InReview", "Blocked", "Failed"];
        }
        else if (currentState == "InReview")
        {
            statusList = ["InReview", "Done", "Active"];
        }
        else if (currentState == "Done")
        {
            statusList = ["Done"];
        }
        else if (currentState == "Blocked")
        {
            statusList = ["Blocked", "Active", "Failed"];
        }
        else if (currentState == "Failed")
        {
            statusList = ["Failed"];
        }
        return statusList;
    }


    async function ErrorHandler(error:Error, SourceFunction:string)
    {    
      let errorLog:ErrorLogType = {file: "Reviews", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }    
      setDatabaseStatusRequest("DatabaseError");
      setDatabaseErrorDesc(errorLog);
    }

    return(
        <Container>

            <Tooltip title={helpDescription} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <Paper elevation={0} 
                    sx={{
                        py: 2,
                        my: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 5
                    }}>
                    <Typography><strong>{helpMessage}</strong></Typography>

                    {IsAuthor&& task.status=="Unassigned"&&
                    <Button 
                    sx={{textTransform: 'none', m: 2 , minWidth:'20%',alignSelf: 'center'}} 
                    variant="contained" 
                    component={(props:any) => <Link {...props} to={"/Home/EditTask/"+id} />} >
                        Edit Task
                    </Button>}

                    {IsMember&&(!IsAuthor)&&(!IsInvited)&&(!IsAcceptedInvitation)&&task.status=="Unassigned"&&
                    <FormGroup>
                        <Box sx={{ alignItems: 'center', m:2}}>
                        <FormControlLabel control={
                        <Switch  
                        checked={((TaskRequests.find(request=>(request.MemberId==currentUserId))?.status=="active")? true:false)}
                        onChange={handleRequestTask}
                        size="medium"
                        />} label="Request Task" />
                        </Box>
                    </FormGroup>
                    }

                    {(IsInvited)&&task.status=="Unassigned"&&
                    <Box>
                    <Button sx={{textTransform: 'none', m: 2 , alignSelf: 'center'}} variant="contained" 
                    onClick={()=>HandleAcceptInvitation(currentUserId!)}>Accept</Button>
                    <Button sx={{textTransform: 'none', m: 2 , alignSelf: 'center'}} variant="contained" color="error"
                    onClick={()=>HandleDeclineInvitation(currentUserId!)}>Decline</Button>
                    </Box>
                    }

                </Paper>
            </Tooltip>

            <Paper elevation={0} 
                    sx={{
                        py: 2,
                        my: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 5,
                        alignItems: 'center'
                    }}>

                    <ToggleButtonGroup
                    exclusive
                    value={NavigationValues}
                    onChange={(event, values)=>{setNavigationValues(values);}}
                    aria-label="text formatting"
                    orientation= {(useWindowDimensions().width<800)? "vertical":"horizontal"}
                    >
                        <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Details">Details</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Status" >Status</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Timeline" >Timeline</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Assignment" disabled={task.status!=="Unassigned"} >Assignment</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Log" disabled={task.status=="Unassigned"} >Log</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Reviews" disabled={task.status!=="InReview"}>Reviews</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Comments"  >Comments</ToggleButton>
                    </ToggleButtonGroup>

                </Paper>

            {NavigationValues.includes("Details")&&
                <DescriptionPanel Task={task} currency={Projects.find((project:any)=>project.id==task.project?.projectID)?.currency!}/>        
            }


            {NavigationValues.includes("Status")&&
            <StateChart State={task.status!} nextStateList={allowedStatus(task.status!)}
            changeStateHandler={(newState:TaskStatusType)=>{
                if(task.assignee?.assigneeId==currentUserId)
                {
                    if(newState!==task.status)
                    {
                        if(newState=="Done")
                        {
                            setopenTaskConcludePanel(true);
                        }
                        else
                        {
                            setTask({...task, status:newState, isDirty:true});
                            setDatabaseStatusRequest("Sync");
                        }    
                    }
                }
                }}/>
            }
            
            {NavigationValues.includes("Timeline")&&
            <TaskTimelineChart startDateString={task.created??new Date().toDateString()} DeadlineString={task.deadline??new Date().toDateString()}/>
            }

            
            {NavigationValues.includes("Assignment") && IsAuthor&&task?.assignee==undefined&&
            <Paper elevation={0} 
            sx={{
                p: 1    ,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 5,
                //alignItems: 'center',
                //":hover": {boxShadow: 15},
            }}>
                <Title>Task Assignment</Title>
                <Grid container spacing={0} rowSpacing={1} sx={{my:3}}>

                {ProjectsMemberships?.filter(membership=>(membership.status=="active")).map((membership, i)=>{
                    //Task author
                    if(membership.userId==task.author?.authorId)
                    return (
                    <Grid item  xs={12} md={3} lg={3} >   
                        <Button sx={{fontSize:12}} variant="outlined" disabled>{membership.memberName}</Button>
                    </Grid>
                    )
                    //Requested task
                    else if(TaskRequests.find(request=>(request.MemberId==membership.userId))?.status=="active")
                    return (
                    <Grid item  xs={12} md={3} lg={3} >
                        <Button sx={{fontSize:12}} variant="contained" color="warning" onDoubleClick={() =>HandleAssignMember(membership.userId??"", membership.memberName??"")}>{membership.memberName}</Button>
                    </Grid>
                    )
                    //Invited member
                    else if(TaskInvitations.find(invitation=>(invitation.MemberId==membership.userId))?.status=="active")
                    return (
                    <Grid item  xs={12} md={3} lg={3} >
                        <Button sx={{fontSize:12}} variant="contained" onDoubleClick={() =>handleInviteToTask(membership.userId??"", membership.memberName??"")}>{membership.memberName}</Button>
                    </Grid>
                    )
                    //Accepted invitation
                    else if(TaskInvitations.find(invitation=>(invitation.MemberId==membership.userId))?.status=="accepted")
                    return (
                    <Grid item  xs={12} md={3} lg={3} >
                        <Button sx={{fontSize:12}} variant="contained" color="success" onDoubleClick={() =>HandleAssignMember(membership.userId??"", membership.memberName??"")}>{membership.memberName}</Button>
                    </Grid>
                    )
                    //Declined invitation
                    else if(TaskInvitations.find(invitation=>(invitation.MemberId==membership.userId))?.status=="declined")
                    return (
                    <Grid item  xs={12} md={3} lg={3} >
                        <Button sx={{fontSize:12}} variant="contained" color="error">{membership.memberName}</Button>
                    </Grid>
                    )
                    //Project member
                    else
                    return (
                    <Grid item  xs={12} md={3} lg={3} >
                        <Button sx={{fontSize:12}} variant="outlined" onDoubleClick={() =>handleInviteToTask(membership.userId??"", membership.memberName??"")}>{membership.memberName}</Button>
                    </Grid>
                    )
                })}


                </Grid>
                <Divider></Divider>

                <Grid container columnSpacing={0} rowSpacing={1} sx={{my:0}}>
                <Grid item  xs={12} md={2} lg={2} >
                    <Button sx={{fontSize:10}} size="small" variant="outlined" disabled>Task Author</Button>
                </Grid>
                <Tooltip title="Double click member's name to invite/uninvite to the task." enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <Grid item  xs={12} md={2} lg={2} >
                    <Button sx={{fontSize:10}} size="small" variant="outlined">Project Member</Button>
                </Grid>
                </Tooltip>
                <Tooltip title="Member has been invited to the task, awaitng his response." enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <Grid item  xs={12} md={2} lg={2} >
                    <Button sx={{fontSize:10}} size="small" variant="contained">Invited</Button>
                </Grid>
                </Tooltip>
                <Tooltip title="Member requested the task, double click to assign." enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <Grid item  xs={12} md={2} lg={2} >
                    <Button sx={{fontSize:10}} size="small" variant="contained" color="warning">Requested</Button>
                </Grid>
                </Tooltip>
                <Tooltip title="Member has accepted the task invitation, double click to assign." enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <Grid item  xs={12} md={2} lg={2} >
                    <Button sx={{fontSize:10}} size="small" variant="contained" color="success">Accepted</Button>
                </Grid>
                </Tooltip>
                <Tooltip title="Member has declined the task invitation." enterTouchDelay={0} leaveTouchDelay={5000} arrow>
                <Grid item  xs={12} md={2} lg={2} >
                    <Button sx={{fontSize:10}} size="small" variant="contained" color="error">Declined</Button>
                </Grid>
                </Tooltip>
                </Grid>
            </Paper>
            }
            
            {NavigationValues.includes("Log") && 
                <CalendarLog Task={task} setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>
            }

            
            {NavigationValues.includes("Reviews") && 
            <ReviewPanel TargetId={task.id??""} setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>
            }

            {/* {NavigationValues.includes("Review") && task.status=="InReview"&&
            <Paper elevation={0} 
            sx={{
                p: 2,
                m:2,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 5
            }}>

                <Typography sx={{m:2}}>Task is currently in review and may be rated, compensations will be moved to the assignee when the task status is updated to Done.</Typography>
                <Title>Task Rating</Title>

                <Box sx={{ mb:4, alignItems: 'center'}}>
                <Rating 
                    name="read-only" 
                    precision={0.1} 
                    value={calcAvgRating()} 
                    readOnly  
                />
                <Typography variant="subtitle2" margin="none">
                    {task?.ratingList?.length??0} Ratings
                </Typography>
                </Box>
                
        
                {(IsMember||IsAuthor)&&(!IsAssignee)&&
                <Box sx={{alignItems: 'center'}}>
                    <Title>Your rating of the Task</Title>
                    <Rating name="set-rating" 
                    precision={1} 
                    value={task?.ratingList?.find(ratingItem => (ratingItem.userId==currentUserId))?.userRating||null}
                    onChange={handleRatingChange}  
                    />
                </Box>
                }

                <Typography sx={{m:2}}>
                    Based on the current rating, compensations would be as follows.
                </Typography>
                <Box sx={{my:1}}>
                <TextField
                    id="outlined-helperText"
                    label="Cash Compensation"
                    value={(task?.compensation?.allocatedCash??0) * ((calcAvgRating()/5)||0)}
                    fullWidth
                    InputProps={{
                        readOnly: true
                    }}                
                />
                </Box>

                <Box sx={{my:1}}>
                <TextField
                    id="outlined-helperText"
                    label="Equity compensation"
                    value={(task?.compensation?.allocatedShares??0) * ((calcAvgRating()/5)||0)}
                    fullWidth
                    InputProps={{
                        readOnly: true
                    }}                 
                />
                </Box>
            </Paper>} */}

            {NavigationValues.includes("Comments") &&
            <CommentSection itemID={task.id!}
            setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>
            }

        <Dialog
            maxWidth="lg"
            open={openTaskConcludePanel}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{setopenTaskConcludePanel(false);}}
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogContent>
            <TaskConclude Task={task} 
            CashAssigned={task.compensation?.allocatedCash??0} 
            SharesAssigned={task.compensation?.allocatedShares??0}
            ConcludeCallback={()=>{
                setopenTaskConcludePanel(false);
                setTask({...task, status:"Done", isDirty:true});
                setDatabaseStatusRequest("Sync");
            }}
            setDatabaseStatusRequest={setDatabaseStatusRequest} 
            setDatabaseErrorDesc={setDatabaseErrorDesc}
            />
        </DialogContent>

    </Dialog>


         </Container>
    );
}