import { ResponsiveStream } from "@nivo/stream";
import { useEffect, useState } from "react";

export default function StreamChart()
{

    const [data, setData] = useState([
        {
          "Raoul": 0,
          "Josiane": 0,
          "Marcel": 0,
          "René": 0,
          "Paul": 0,
          "Jacques": 0
        },
        {
          "Raoul": 0,
          "Josiane": 0,
          "Marcel": 0,
          "René": 0,
          "Paul": 0,
          "Jacques": 0
        },
        {
          "Raoul": 0,
          "Josiane": 0,
          "Marcel": 0,
          "René": 0,
          "Paul": 0,
          "Jacques": 0
        },
        {
          "Raoul": 0,
          "Josiane": 0,
          "Marcel": 0,
          "René": 0,
          "Paul": 0,
          "Jacques": 0
        },
        {
          "Raoul": 0,
          "Josiane": 0,
          "Marcel": 0,
          "René": 0,
          "Paul": 0,
          "Jacques": 0
        },
        {
          "Raoul": 0,
          "Josiane": 0,
          "Marcel": 0,
          "René": 0,
          "Paul": 0,
          "Jacques": 0
        },
        {
          "Raoul": 0,
          "Josiane": 0,
          "Marcel": 0,
          "René": 0,
          "Paul": 0,
          "Jacques": 0
        },
        {
          "Raoul": 0,
          "Josiane": 0,
          "Marcel": 0,
          "René": 0,
          "Paul": 0,
          "Jacques": 0
        },
        {
          "Raoul": 0,
          "Josiane": 0,
          "Marcel": 0,
          "René": 0,
          "Paul": 0,
          "Jacques": 0
        }
      ]);
    
    useEffect(()=>{

        setData( [
            {
              "Raoul": Math.random()*255,
              "Josiane": Math.random()*255,
              "Marcel": Math.random()*255,
              "René": Math.random()*255,
              "Paul": Math.random()*255,
              "Jacques": Math.random()*255
            },
            {
              "Raoul": Math.random()*255,
              "Josiane": Math.random()*255,
              "Marcel": Math.random()*255,
              "René": Math.random()*255,
              "Paul": Math.random()*255,
              "Jacques": Math.random()*255
            },
            {
              "Raoul": Math.random()*255,
              "Josiane": Math.random()*255,
              "Marcel": Math.random()*255,
              "René": Math.random()*255,
              "Paul": Math.random()*255,
              "Jacques": Math.random()*255
            },
            {
              "Raoul": Math.random()*255,
              "Josiane": Math.random()*255,
              "Marcel": Math.random()*255,
              "René": Math.random()*255,
              "Paul": Math.random()*255,
              "Jacques": Math.random()*255
            },
            {
              "Raoul": Math.random()*255,
              "Josiane": Math.random()*255,
              "Marcel": Math.random()*255,
              "René": Math.random()*255,
              "Paul": Math.random()*255,
              "Jacques": Math.random()*255
            },
            {
              "Raoul": Math.random()*255,
              "Josiane": Math.random()*255,
              "Marcel": Math.random()*255,
              "René": Math.random()*255,
              "Paul": Math.random()*255,
              "Jacques": Math.random()*255
            },
            {
              "Raoul": Math.random()*255,
              "Josiane": Math.random()*255,
              "Marcel": Math.random()*255,
              "René": Math.random()*255,
              "Paul": Math.random()*255,
              "Jacques": Math.random()*255
            },
            {
              "Raoul": Math.random()*255,
              "Josiane": Math.random()*255,
              "Marcel": Math.random()*255,
              "René": Math.random()*255,
              "Paul": Math.random()*255,
              "Jacques": Math.random()*255
            },
            {
              "Raoul": Math.random()*255,
              "Josiane": Math.random()*255,
              "Marcel": Math.random()*255,
              "René": Math.random()*255,
              "Paul": Math.random()*255,
              "Jacques": Math.random()*255
            }
          ])
    
    },[]);
    

    return(
        <ResponsiveStream
        data={data}
        keys={[
            'Raoul',
            'Josiane',
            'Marcel',
            'René',
            'Paul',
            'Jacques'
        ]}
        axisTop={null}
        axisRight={null}
        enableGridX={false}
        enableGridY={false}
        offsetType="silhouette"
        colors={{ scheme: 'nivo' }}
        fillOpacity={0.3}
        borderColor={{ theme: 'background' }}
        isInteractive={false}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#2c998f',
                size: 4,
                padding: 2,
                stagger: true
            },
            {
                id: 'squares',
                type: 'patternSquares',
                background: 'inherit',
                color: '#e4c912',
                size: 6,
                padding: 2,
                stagger: true
            }
        ]}
        fill={[
            {
                match: {
                    id: 'Paul'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'Marcel'
                },
                id: 'squares'
            }
        ]}
        dotSize={8}
        dotColor={{ from: 'color' }}
        dotBorderWidth={2}
        dotBorderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.7
                ]
            ]
        }}
    />
    );
}