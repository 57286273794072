import { Box, Fab, Tooltip } from "@mui/material";
import CloudIcon from '@mui/icons-material/Cloud';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import ReplayIcon from '@mui/icons-material/Replay';
import { useEffect } from "react";
import { ConnectionStatusType, ErrorLogType } from "./Interfaces";
import React from "react";
import { stat } from "fs";
import { db, IsConnected } from "../firebase";
import useState from 'react-usestateref';
import { addDoc, collection } from "firebase/firestore";


export default function CloudStatus({DatabaseStatusRequest, DatabaseErrorDesc}:{DatabaseStatusRequest:ConnectionStatusType, DatabaseErrorDesc: ErrorLogType}) {


    const [status, setStatus, statusRef] = useState<ConnectionStatusType>("None");
    const [errorDesc, setErrorDesc, errorDescRef] = useState<string>("");


    function ManageCloudStatus(DatabaseStatusRequest:ConnectionStatusType, CloudStatusRequest:ConnectionStatusType)
    {
        if(CloudStatusRequest == "ConnectionError")
        {
            setStatus("ConnectionError");
        }
        else if(DatabaseStatusRequest == "DatabaseError")
        {
            setStatus("DatabaseError");
            setErrorDesc(DatabaseErrorDesc.message);
        }
        else if(DatabaseStatusRequest == "Sync")
        {
            setStatus("Sync");
        }    
        else
        {
            setStatus("OK");
        }
    }

    React.useEffect(() =>{
        console.log(DatabaseStatusRequest)
        ManageCloudStatus(DatabaseStatusRequest, "None");
    },
    [DatabaseStatusRequest]
    );


    async function LogError()
    {
        if(status=="ConnectionError" || status=="DatabaseError")
        {
            await addDoc(collection(db, "ErrorLogs"), DatabaseErrorDesc );
        }    
    }

    React.useEffect(
    () =>{
        LogError();
    },
    [status]
    );

    useEffect(
    ()=>{
        const interval = setInterval(async () => {
            let firebaseStatus = await IsConnected();
            ManageCloudStatus("None" ,firebaseStatus);
        },10*1000);
        return () => clearInterval(interval);
    },[]);

    return (
    <Box>

        {status=="None" &&
        <Fab sx={{mx:3, mb:2}} size='small' color="inherit" aria-label="add">
            <CloudIcon/>
        </Fab>
        }


        {status=="OK" &&
        <Tooltip title={"Connected"} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
        <Fab sx={{mx:3, mb:2}} size='small' color="success" aria-label="add">
            <CloudDoneIcon/>
        </Fab>
        </Tooltip>
        }


        {status=="DatabaseError" &&
        <Tooltip title={"Database error: "+errorDesc} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
        <Fab sx={{mx:3, mb:2}} size='small' color="error" aria-label="add" >
            <ThunderstormIcon/>
        </Fab>
        </Tooltip>
        }

        {status=="ConnectionError" &&
        <Tooltip title={"Connection error"} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
        <Fab sx={{mx:3, mb:2}} size='small' color="error" aria-label="add" >
            <CloudOffIcon/>
        </Fab>
        </Tooltip>
        }

        {status=="Sync" &&
        <Fab sx={{mx:3, mb:2}} size='small' color="info" aria-label="add">
            <CloudSyncIcon/>
        </Fab>
        }

    </Box>

    );

}
