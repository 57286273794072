import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import useWindowDimensions from "./useWindowDimensions"
import { useState } from "react";
import CreateOrEditProject from "./CreateOrEditProject";


export default function CreateProjectSwitch({setDatabaseStatusRequest, setDatabaseErrorDesc}:
    {setDatabaseStatusRequest:Function, setDatabaseErrorDesc:Function}){


    const [View, setView] = useState<string>("Story");


    return(
        <div>
        <ToggleButtonGroup
        sx={{m:2}}
        value={View}
        onChange={(event, value)=>{
            if(value!==null)
            {setView(value)}
        }}
        aria-label="text formatting"
        exclusive
        orientation= {(useWindowDimensions().width<800)? "vertical":"horizontal"}
        >
            <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Story">Story View</ToggleButton>
            <ToggleButton sx={{fontSize:'min(2.5vw,10px)'}} value="Quick" >Quick View</ToggleButton>
        </ToggleButtonGroup>


        {View=="Story" && <CreateOrEditProject IsStoryNotQuick={true} IsCreateNotEdit={true} 
        setDatabaseStatusRequest={setDatabaseStatusRequest} setDatabaseErrorDesc={setDatabaseErrorDesc}/>}
        {View=="Quick" && <CreateOrEditProject IsStoryNotQuick={false} IsCreateNotEdit={true} 
        setDatabaseStatusRequest={setDatabaseStatusRequest}  setDatabaseErrorDesc={setDatabaseErrorDesc}/>}

        </div>
    )

}