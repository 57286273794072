import { Box, Typography } from "@mui/material";
import Logoimage from '../Image/logo4.png'; 
import EffortFundDynLogo from "./EffortFundDynLogo";

export default function LogoComp()
{
    return (
        <Box sx={{alignSelf:'start', ml:'6vw', my:2, display:'flex', flexDirection:'column'}}>

        <Box sx={{height:'8vh', width:'8vw', alignSelf:'center'}}>
        <EffortFundDynLogo/>
        </Box>

        <Typography fontFamily={'revert'}  fontWeight={'bolder'} color={'rgb(37, 150, 190)'} 
        sx={{fontSize:'min(7vw,50px)', textShadow:'1px 1px 1px', WebkitTextStroke:'0.5px black'}}>EffortFund</Typography>
        <Typography fontFamily={'fantasy'} color={'black'} fontWeight={'normal'} sx={{ fontSize:'min(2.8vw,13px)', mt:-1}}>A Partnership Management Platform</Typography>
      </Box>
    );
}

