import { Box, Container, LinearProgress, Paper, Typography } from "@mui/material";
import Title from "./Title";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgressWithLabel from "./LinearProgressWithLabel";

  
export default function Progress({value}:{value:number})
{
    return(
            <Paper elevation={0} 
                    sx={{
                    p: 2,
                    my: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 5
                    }}
            >
                <Title>Project Progress</Title>

                <LinearProgressWithLabel value={value} />

            </Paper>          
    );
}