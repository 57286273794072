import { Box, Button, Divider, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { auth, GuestSignIn, IsUser, logInWithEmailAndPassword, registerWithEmailAndPassword, signInWithGoogle } from "../firebase";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "./useWindowDimensions";



export default function RegisterPanel({context}:{context:("Default"|"Guest")})
{

    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState("");
    const [name, setname] = React.useState("");

    const [emailErrorText, setemailErrorText] = React.useState<string>("Email");
    const [passwordErrorText, setPasswordErrorText] = React.useState<string>("Password");
    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

    const history = useHistory();

    React.useEffect(() =>{
        if(email=="")
        {
            setemailErrorText("Email");
        }
        else
        {
            setemailErrorText("");
        }

        if(password=="")
        {
            setPasswordErrorText("Password");
        }
        else
        {
            setPasswordErrorText("");
        }
    },
    [email, password]
    );

    async function LoginIfUserorRegisterOtherwise()
    {
      if(await IsUser(email))
      {
        logInWithEmailAndPassword(email, password);
      }
      else
      {
        registerWithEmailAndPassword(name,email, password, context);
      } 
    }

    return(
        <Paper elevation={2} sx={{p:3, borderRadius:5, display:'flex', flexDirection:(useWindowDimensions().width>800)?'row':'column', justifyContent:'center',backgroundColor:'transparent'}}>

        <Box sx={{mt:1, mx:3, display:'flex', flexDirection:(useWindowDimensions().width>800)?'row':'column', justifyContent:'center'}}>

          <TextField
              sx={{mx:2, backgroundColor:'transparent', alignSelf:'center'}}
              variant='standard'
              label="Email"
              value={email||''}
              error={(emailErrorText=="Email"||emailErrorText=="")? false : true}
              helperText={emailErrorText}
              onChange={(e) => {setEmail(e.target.value); setname(e.target.value.split('@')[0])}}
              inputProps={{ style: inputStyle }} 
              />
          <TextField
              sx={{mx:2, backgroundColor:'transparent', alignSelf:'center'}}
              variant='standard'
              label="Password"
              value={password||''}
              error={(passwordErrorText=="Password"||passwordErrorText=="")? false : true}
              helperText={passwordErrorText}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              inputProps={{ style: inputStyle }} 
              />

            {context=="Default"&&
            <Button sx={{textTransform: 'none', borderRadius: 5, minWidth:'10vw', m:0.5, mt:1, fontSize:'min(3.5vw,13px)', alignSelf:'center'}} variant="outlined"  
            onClick={() =>
                {
                if(email==""){setemailErrorText("Email is required."); return;}
                if(password==""){setPasswordErrorText("Password is required."); return;}
                logInWithEmailAndPassword(email, password).then().
                catch((err:ErrorEvent)=>{
                    switch(err.message)
                    {
                    case("Firebase: Error (auth/user-not-found)."):{setemailErrorText("Email isn't registered. Please sign up."); break;}
                    case("Firebase: Error (auth/invalid-email)."):{setemailErrorText("Email is invalid."); break;}
                    case("Firebase: Error (auth/wrong-password)."):{setPasswordErrorText("Incorrect Password."); break;}
                    case("Firebase: Error (auth/internal-error)."):{setPasswordErrorText("Authentication Error."); break;}
                    default:{setemailErrorText("Unknown Error.")}
                    }
                })
                }
                } 
                >Login</Button>
                } 
    
            <Button sx={{textTransform: 'none', borderRadius: 5, minWidth:'10vw', m:0.5, mt:1, fontSize:'min(3.5vw,13px)', alignSelf:'center'}} variant="outlined"
            onClick={() =>
                {
                if(email==""){setemailErrorText("Email is required."); return;}
                if(password==""){setPasswordErrorText("Password is required."); return;}
                registerWithEmailAndPassword(name,email, password, context).
                catch((err:ErrorEvent)=>{
                    switch(err.message)
                    {
                    case("Firebase: Error (auth/email-already-in-use)."):{setemailErrorText("Email is already in use."); break;}
                    case("Firebase: Error (auth/invalid-email)."):{setemailErrorText("Email is invalid."); break;}
                    case("Firebase: Password should be at least 6 characters (auth/weak-password)."):{setPasswordErrorText("Password should be at least 6 characters."); break;}
                    case("Firebase: Error (auth/internal-error)."):{setPasswordErrorText("Authentication Error."); break;}
                    default:{setemailErrorText("Unknown Error.")}
                    }
                }).then(()=>{history.push("/");})
                }
            }             
            >Sign Up</Button> 
          </Box>
    
          <Divider orientation={(useWindowDimensions().width>800)?"vertical":"horizontal"} flexItem />

          <Box sx={{mt:1, mx:3}}>
            <Button sx={{textTransform: 'none', borderRadius: 5, minWidth:'10vw', m:0.5, mt:1, fontSize:'min(3.5vw,13px)'}} variant="contained"
            onClick={()=>{signInWithGoogle(context).then(()=>{history.push("/");})}} >Login using Google</Button> 
          </Box>
  
        {context=="Default"&&
        <Divider orientation="vertical" flexItem />
        }

          <Box sx={{mt:1, mx:3}}>
            {context=="Default"&&
            <Button sx={{textTransform: 'none', borderRadius: 5, minWidth:'10vw', m:0.5, mt:1, fontSize:'min(3.5vw,13px)'}} variant="contained" color="success"
            onClick={()=>{GuestSignIn()}} >Login as a Guest</Button>
            } 
          </Box>
  
          </Paper>
  
    );
}
